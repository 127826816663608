import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-rows-* grid-cols-3 gap-4 mt-8"
}
const _hoisted_2 = { class: "border border-2 dark:bg-dark-dark_gray bg-gray p-4" }
const _hoisted_3 = { class: "flex items-center mb-8" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = {
  key: 1,
  class: "flex justify-between items-center"
}
const _hoisted_7 = { class: "flex items-center cursor-pointer hover:opacity-50" }
const _hoisted_8 = { key: 0 }

import { SidebarLoader } from '@/05-Features/SidebarLoader'
import fileLoader, { FileLoaderType } from '@/06-Entities/File/model/FileLoader'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { computed, Ref, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { IAddService, IAddServicesList } from '@/entityes/invoice/invoice.type'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import img from '@/07-Shared/ui/ImgDescriber'
import GuruLogo from '@/assets/guru_logo.svg'
import { ElMessageBox, ElNotification } from 'element-plus'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { SetUp, SuccessFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
import { FileFromServerItem, transformFileFromServer } from '@/06-Entities/File'

interface IProps {
  invoiceId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DeductionCustomerUI',
  props: {
    invoiceId: {}
  },
  setup(__props: any) {

const props = __props
const { getAdditionalServices, updateAdditionalService } = useInvoiceRepository()
const { colors, isDark } = useColors()
const showLoaderSideBar = ref(false)
const deductionServices: Ref<IAddService[] | undefined> = ref(undefined)
const { incomesDeductionsDescribers: describer, refunds } = AddIncomesRefundsDescriber()
const approvedDeductionTags = refunds.items.flatMap((item) => item.refunds).map((refund) => refund.tag)
const renderDeductionServices = computed(() => {
  return deductionServices.value?.filter((service) => approvedDeductionTags.includes(service.service_type))
})
const loading = ref(false)
const loaderConfig = ref<ILoaderDescriber | null>(null)
const loader = ref<FileLoaderType | undefined>(undefined)
const service = ref<IAddService | undefined>(undefined)

function initLoader(type: string, open: boolean) {
  const getName = describer.find((i) => i.tag === type)?.name
  loaderConfig.value = {
    title: `${getName}`,
    text: `Нажмите или перетащите файлы ${getName}`,
    serverTag: 'service_files',
    name: 'AdditionalServiceFiles',
    fileFormat: /.*\.(csv|xlsx|xls|pdf|xml|jpeg|jpg|png|json|docx|doc|XLSX|PDF|CSV|XML|JPEG|JPG|PNG)$/,
    img: img.csv,
    logo: GuruLogo,
  }
  loader.value = fileLoader(loaderConfig.value)
  service.value = deductionServices.value?.find((i) => i.service_type === type)
  showLoaderSideBar.value = open
}
function saveFiles() {
  loading.value = true
  if (!!loader.value) {
    const body = {
      id: service.value?.id,
      service_type: service.value?.service_type,
      service_files: {
        additional_service_files: {
          files: [...loader.value?.localFiles.files],
          file_names: [...loader.value?.localFiles.file_names],
          delete_files: [...loader.value.deleteFiles],
        },
      },
    }
    updateAdditionalService(props.invoiceId, body)
      .then(() => {
        getServices()
        ElNotification({
          title: 'Успешно',
          message: `обновлены данные для ${describer.find((i) => i.tag === service.value?.service_type)?.name}`,
          type: 'success',
        })
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `обновления данных для ${
            describer.find((i) => i.tag === service.value?.service_type)?.name
          } | ${err}`,
          type: 'error',
        })
      })
      .finally(() => (loading.value = false))
  }
}
function downLoadFile(file: FileFromServer) {
  if (!!service.value) {
    const params = {
      invoiceId: props.invoiceId,
      serviceId: service.value.id,
      file,
    }
    loader.value?.downloadAdditionalServiceFile(params)
  }
}
function getServices() {
  getAdditionalServices(props.invoiceId).then((res: IAddServicesList) => {
    deductionServices.value = res?.additional_services
  })
}
function deleteFilesFromCard(fileId: number) {
  loader.value?.onAddDeleteFile(fileId)
  const fileName = service.value?.files.find((file) => file.id === fileId)?.name
  ElMessageBox.confirm(`Вы хотите удалить файл ${fileName}`, 'Внимание!', {
    confirmButtonText: 'Удалить файл',
    cancelButtonText: 'Отменить',
    type: 'warning',
    buttonSize: 'small',
  })
    .then(() => {
      saveFiles()
    })
    .catch(() => {})
}

watch(loader, () => {
  if (!!service.value) {
    loader.value?.setFilesFromServer(service.value?.files.map((i) => transformFileFromServer(i)))
  }
})

getServices()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!deductionServices.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderDeductionServices.value, (service) => {
            return (_openBlock(), _createElementBlock("div", {
              key: service.id
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    class: "mr-2",
                    width: "20px",
                    src: _unref(describer).find((i) => i.tag === service.service_type)?.icon,
                    alt: "icon"
                  }, null, 8, _hoisted_4),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(describer).find((i) => i.tag === service.service_type)?.name), 1)
                ]),
                (service.files.length === 0)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "small",
                      onClick: ($event: any) => (initLoader(service.service_type, true)),
                      type: "primary"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Загрузить файлы")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_el_popover, {
                        onShow: ($event: any) => (initLoader(service.service_type, false)),
                        placement: "right",
                        width: 400,
                        trigger: "click"
                      }, {
                        reference: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_el_icon, {
                              class: "mr-1",
                              color: _unref(isDark) ? _unref(colors).dark.ng_green : _unref(colors).light.ng_green
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(SuccessFilled))
                              ]),
                              _: 1
                            }, 8, ["color"]),
                            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "dark:text-dark-green text-light-green" }, "Файлы загружены", -1))
                          ])
                        ]),
                        default: _withCtx(() => [
                          (!!loader.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_unref(FileFromServerItem), {
                                  files: service.files.map((file) => _unref(transformFileFromServer)(file)),
                                  "no-delete": false,
                                  onDelete: deleteFilesFromCard,
                                  onDownload: downLoadFile
                                }, null, 8, ["files"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onShow"]),
                      _createVNode(_component_el_button, {
                        onClick: ($event: any) => (initLoader(service.service_type, true)),
                        icon: _unref(SetUp),
                        link: "",
                        type: "primary",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Изменить ")
                        ])),
                        _: 2
                      }, 1032, ["onClick", "icon"])
                    ]))
              ])
            ]))
          }), 128))
        ])), [
          [_directive_loading, loading.value]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (loader.value)
        ? (_openBlock(), _createBlock(_unref(SidebarLoader), {
            key: 0,
            "loader-describer": loader.value,
            "show-instruction": false,
            "show-sidebar": showLoaderSideBar.value,
            "onUpdate:showSidebar": _cache[0] || (_cache[0] = ($event: any) => ((showLoaderSideBar).value = $event)),
            onReadedFile: loader.value.onUpdateFilesEvent,
            onDeleteLocalFile: loader.value.onRemoveLocalFile,
            onDeleteServerFile: loader.value.onAddDeleteFile,
            onUndoDeleteFile: loader.value.undoDeleteFiles,
            onSaveFiles: saveFiles,
            onDownloadServerFile: downLoadFile,
            "show-server-files": "",
            "show-delete-button": ""
          }, null, 8, ["loader-describer", "show-sidebar", "onReadedFile", "onDeleteLocalFile", "onDeleteServerFile", "onUndoDeleteFile"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})