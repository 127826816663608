import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "mb-8 flex items-center" }
const _hoisted_2 = { class: "p-4 font-bold" }

import useWindowWidth from '@/use/useWindowWidth'
import { defineAsyncComponent, reactive, ref } from 'vue'
import { useGetOnlineCfrInfo } from '@/05-Features/CfrOnlineResults/api/getOnlineCfrInfo.request'
import { ElNotification } from 'element-plus'
import { ICfrOnline } from '@/entityes/invoice/cfr/cfr.type'
import { IFile } from '@/06-Entities/File'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'
import CfrOnlineZero from '@/06-Entities/ResultFiles/ui/CfrOnlineZero.vue'
import { Link } from '@element-plus/icons-vue'
interface IProps {
  goodId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CfrOnlineResults',
  props: {
    goodId: {}
  },
  setup(__props: any) {

const CfrOnlineAccCard = defineAsyncComponent(() => import('@/06-Entities/ResultFiles/ui/CfrOnlineAccCard.vue'))
const props = __props
const { getCfrOnlineInfo } = useGetOnlineCfrInfo()
const cfrAccounts = ref<ICfrOnline[]>([])
const { screenLessValue } = useWindowWidth()
const loading = ref(false)

function init() {
  loading.value = true
  getCfrOnlineInfo(props.goodId)
    .then((res) => {
      cfrAccounts.value = [...res.data]
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `получения данных для онлайн ОДС ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
init()

return (_ctx: any,_cache: any) => {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (cfrAccounts.value.length === 0 && !loading.value)
      ? (_openBlock(), _createBlock(_component_el_empty, {
          key: 0,
          description: "Нет данных",
          "image-size": "60"
        }))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _withDirectives(_createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "mb-4" }, "Данные для заполнения ОДС на сайте налоговой", -1)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_icon, {
            size: "16",
            class: "mr-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Link))
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createElementVNode("a", {
            href: "https://help.ndfl.guru/i8",
            class: "clickable-text-link",
            target: "_blank"
          }, "Инструкция для подачи ОДС онлайн через личный кабинет ФНС", -1))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cfrAccounts.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id
          }, [
            _createVNode(_component_el_collapse, { class: "mb-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_collapse_item, null, {
                  title: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, [
                      _cache[1] || (_cache[1] = _createElementVNode("span", null, "Счет ", -1)),
                      _createElementVNode("span", null, _toDisplayString(item.account_number), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(_unref(screenLessValue)(767) ? '' : 'p-4')
                    }, [
                      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "mb-4" }, "Информация о движении средств и стоимости иных финансовых активов", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item?.broker_accounts, (accountInfo) => {
                        return (_openBlock(), _createBlock(_unref(CfrOnlineAccCard), {
                          key: accountInfo.id,
                          "broker-account": accountInfo
                        }, null, 8, ["broker-account"]))
                      }), 128)),
                      (item?.broker_accounts.length === 0)
                        ? (_openBlock(), _createBlock(CfrOnlineZero, {
                            key: 0,
                            "country-code": item?.broker_country_code
                          }, null, 8, ["country-code"]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ], 512), [
        [_vShow, cfrAccounts.value.length > 0]
      ])
    ])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})