export interface ICountry {
  shortname: string
  global_id: number
  fullname: string
  code: string | number
  alf2: string
  alf3: string
}
export default function useCountryDescriber() {
  const countryArray: ICountry[] = [
    {
      shortname: 'АВСТРИЯ',
      global_id: 273329414,
      fullname: 'Австрийская Республика',
      code: 40,
      alf2: 'AT',
      alf3: 'AUT',
    },
    {
      shortname: 'Белиз',
      global_id: 123456789,
      fullname: 'Белиз',
      code: '084',
      alf2: 'BZ',
      alf3: 'BLZ',
    },
    {
      shortname: 'ЧАД',
      global_id: 273329415,
      fullname: 'Республика Чад',
      code: 148,
      alf2: 'TD',
      alf3: 'TCD',
    },
    {
      shortname: 'КОМОРЫ',
      global_id: 273329416,
      fullname: 'Союз Коморы',
      code: 174,
      alf2: 'KM',
      alf3: 'COM',
    },
    {
      shortname: 'ГАБОН',
      global_id: 273329417,
      fullname: 'Габонская Республика',
      code: 266,
      alf2: 'GA',
      alf3: 'GAB',
    },
    {
      shortname: 'ПАЛЕСТИНА',
      global_id: 273329418,
      fullname: 'Государство Палестина',
      code: 275,
      alf2: 'PS',
      alf3: 'PSE',
    },
    {
      shortname: 'ГВАДЕЛУПА',
      global_id: 273329419,
      fullname: '',
      code: 312,
      alf2: 'GP',
      alf3: 'GLP',
    },
    {
      shortname: 'ГОНДУРАС',
      global_id: 273329420,
      fullname: 'Республика Гондурас',
      code: 340,
      alf2: 'HN',
      alf3: 'HND',
    },
    {
      shortname: 'ИРАН',
      global_id: 273329421,
      fullname: 'Исламская Республика Иран',
      code: 364,
      alf2: 'IR',
      alf3: 'IRN',
    },
    {
      shortname: 'ИРЛАНДИЯ',
      global_id: 273329422,
      fullname: '',
      code: 372,
      alf2: 'IE',
      alf3: 'IRL',
    },
    {
      shortname: 'ЯПОНИЯ',
      global_id: 273329423,
      fullname: '',
      code: 392,
      alf2: 'JP',
      alf3: 'JPN',
    },
    {
      shortname: 'КЕНИЯ',
      global_id: 273329424,
      fullname: 'Республика Кения',
      code: 404,
      alf2: 'KE',
      alf3: 'KEN',
    },
    {
      shortname: 'КИРГИЗИЯ',
      global_id: 273329425,
      fullname: 'Киргизская Республика',
      code: 417,
      alf2: 'KG',
      alf3: 'KGZ',
    },
    {
      shortname: 'МАЛЬТА',
      global_id: 273329426,
      fullname: 'Республика Мальта',
      code: 470,
      alf2: 'MT',
      alf3: 'MLT',
    },
    {
      shortname: 'МОЗАМБИК',
      global_id: 273329427,
      fullname: 'Республика Мозамбик',
      code: 508,
      alf2: 'МZ',
      alf3: 'MOZ',
    },
    {
      shortname: 'АРУБА',
      global_id: 273329428,
      fullname: '',
      code: 533,
      alf2: 'AW',
      alf3: 'ABW',
    },
    {
      shortname: 'НОВАЯ КАЛЕДОНИЯ',
      global_id: 273329429,
      fullname: '',
      code: 540,
      alf2: 'NC',
      alf3: 'NCL',
    },
    {
      shortname: 'СЛОВАКИЯ',
      global_id: 273329430,
      fullname: 'Словацкая Республика',
      code: 703,
      alf2: 'SK',
      alf3: 'SVK',
    },
    {
      shortname: 'ЭСВАТИНИ',
      global_id: 273329431,
      fullname: 'Королевство Эсватини',
      code: 748,
      alf2: 'SZ',
      alf3: 'SWZ',
    },
    {
      shortname: 'ТАНЗАНИЯ',
      global_id: 273329432,
      fullname: 'Объединенная Республика Танзания',
      code: 834,
      alf2: 'TZ',
      alf3: 'TZA',
    },
    {
      shortname: 'УОЛЛИС И ФУТУНА',
      global_id: 273329433,
      fullname: '',
      code: 876,
      alf2: 'WF',
      alf3: 'WLF',
    },
    {
      shortname: 'СИНГАПУР',
      global_id: 273330121,
      fullname: 'Республика Сингапур',
      code: 702,
      alf2: 'SG',
      alf3: 'SGP',
    },
    {
      shortname: 'ВЬЕТНАМ',
      global_id: 273330122,
      fullname: 'Социалистическая Республика Вьетнам',
      code: 704,
      alf2: 'VN',
      alf3: 'VNM',
    },
    {
      shortname: 'СЛОВЕНИЯ',
      global_id: 273330123,
      fullname: 'Республика Словения',
      code: 705,
      alf2: 'SI',
      alf3: 'SVN',
    },
    {
      shortname: 'СОМАЛИ',
      global_id: 273330124,
      fullname: 'Федеративная Республика Сомали',
      code: 706,
      alf2: 'SO',
      alf3: 'SOM',
    },
    {
      shortname: 'ЮЖНАЯ АФРИКА',
      global_id: 273330125,
      fullname: 'Южно-Африканская Республика',
      code: 710,
      alf2: 'ZA',
      alf3: 'ZAF',
    },
    {
      shortname: 'ЗИМБАБВЕ',
      global_id: 273330126,
      fullname: 'Республика Зимбабве',
      code: 716,
      alf2: 'ZW',
      alf3: 'ZWE',
    },
    {
      shortname: 'ИСПАНИЯ',
      global_id: 273330127,
      fullname: 'Королевство Испания',
      code: 724,
      alf2: 'ES',
      alf3: 'ESP',
    },
    {
      shortname: 'ЮЖНЫЙ СУДАН',
      global_id: 273330128,
      fullname: 'Республика Южный Судан',
      code: 728,
      alf2: 'SS',
      alf3: 'SSD',
    },
    {
      shortname: 'СУДАН',
      global_id: 273330129,
      fullname: 'Республика Судан',
      code: 729,
      alf2: 'SD',
      alf3: 'SDN',
    },
    {
      shortname: 'ЗАПАДНАЯ САХАРА',
      global_id: 273330130,
      fullname: '',
      code: 732,
      alf2: 'EH',
      alf3: 'ESH',
    },
    {
      shortname: 'СУРИНАМ',
      global_id: 273330131,
      fullname: 'Республика Суринам',
      code: 740,
      alf2: 'SR',
      alf3: 'SUR',
    },
    {
      shortname: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
      global_id: 273330132,
      fullname: '',
      code: 744,
      alf2: 'SJ',
      alf3: 'SJM',
    },
    {
      shortname: 'ШВЕЦИЯ',
      global_id: 273330133,
      fullname: 'Королевство Швеция',
      code: 752,
      alf2: 'SE',
      alf3: 'SWE',
    },
    {
      shortname: 'ШВЕЙЦАРИЯ',
      global_id: 273330134,
      fullname: 'Швейцарская Конфедерация',
      code: 756,
      alf2: 'CH',
      alf3: 'CHE',
    },
    {
      shortname: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
      global_id: 273330135,
      fullname: '',
      code: 760,
      alf2: 'SY',
      alf3: 'SYR',
    },
    {
      shortname: 'ТАДЖИКИСТАН',
      global_id: 273330136,
      fullname: 'Республика Таджикистан',
      code: 762,
      alf2: 'TJ',
      alf3: 'TJK',
    },
    {
      shortname: 'ТАИЛАНД',
      global_id: 273330137,
      fullname: 'Королевство Таиланд',
      code: 764,
      alf2: 'TH',
      alf3: 'THA',
    },
    {
      shortname: 'ТОГО',
      global_id: 273330138,
      fullname: 'Тоголезская Республика',
      code: 768,
      alf2: 'TG',
      alf3: 'TGO',
    },
    {
      shortname: 'ТОКЕЛАУ',
      global_id: 273330139,
      fullname: '',
      code: 772,
      alf2: 'TK',
      alf3: 'TKL',
    },
    {
      shortname: 'ТОНГА',
      global_id: 273330140,
      fullname: 'Королевство Тонга',
      code: 776,
      alf2: 'TO',
      alf3: 'TON',
    },
    {
      shortname: 'ТРИНИДАД И ТОБАГО',
      global_id: 273330141,
      fullname: 'Республика Тринидад и Тобаго',
      code: 780,
      alf2: 'TT',
      alf3: 'TTO',
    },
    {
      shortname: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
      global_id: 273330142,
      fullname: '',
      code: 784,
      alf2: 'AE',
      alf3: 'ARE',
    },
    {
      shortname: 'ТУНИС',
      global_id: 273330143,
      fullname: 'Тунисская Республика',
      code: 788,
      alf2: 'TN',
      alf3: 'TUN',
    },
    {
      shortname: 'ТУРЦИЯ',
      global_id: 273330144,
      fullname: 'Турецкая Республика',
      code: 792,
      alf2: 'TR',
      alf3: 'TUR',
    },
    {
      shortname: 'ТУРКМЕНИСТАН',
      global_id: 273330145,
      fullname: '',
      code: 795,
      alf2: 'TM',
      alf3: 'TKM',
    },
    {
      shortname: 'ОСТРОВА ТЕРКС И КАЙКОС',
      global_id: 273330146,
      fullname: '',
      code: 796,
      alf2: 'TC',
      alf3: 'TCA',
    },
    {
      shortname: 'ТУВАЛУ',
      global_id: 273330147,
      fullname: '',
      code: 798,
      alf2: 'TV',
      alf3: 'TUV',
    },
    {
      shortname: 'УГАНДА',
      global_id: 273330148,
      fullname: 'Республика Уганда',
      code: 800,
      alf2: 'UG',
      alf3: 'UGA',
    },
    {
      shortname: 'УКРАИНА',
      global_id: 273330149,
      fullname: '',
      code: 804,
      alf2: 'UA',
      alf3: 'UKR',
    },
    {
      shortname: 'СЕВЕРНАЯ МАКЕДОНИЯ',
      global_id: 273330150,
      fullname: 'Республика Северная Македония',
      code: 807,
      alf2: 'MK',
      alf3: 'MKD',
    },
    {
      shortname: 'ЕГИПЕТ',
      global_id: 273330151,
      fullname: 'Арабская Республика Египет',
      code: 818,
      alf2: 'EG',
      alf3: 'EGY',
    },
    {
      shortname: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
      global_id: 273330152,
      fullname: 'Соединенное Королевство Великобритании и Северной Ирландии',
      code: 826,
      alf2: 'GB',
      alf3: 'GBR',
    },
    {
      shortname: 'ГЕРНСИ',
      global_id: 273330153,
      fullname: '',
      code: 831,
      alf2: 'GG',
      alf3: 'GGY',
    },
    {
      shortname: 'ДЖЕРСИ',
      global_id: 273330154,
      fullname: '',
      code: 832,
      alf2: 'JE',
      alf3: 'JEY',
    },
    {
      shortname: 'ОСТРОВ МЭН',
      global_id: 273330155,
      fullname: '',
      code: 833,
      alf2: 'IM',
      alf3: 'IMN',
    },
    {
      shortname: 'СОЕДИНЕННЫЕ ШТАТЫ АМЕРИКИ',
      global_id: 273330156,
      fullname: 'Соединенные Штаты Америки',
      code: 840,
      alf2: 'US',
      alf3: 'USA',
    },
    {
      shortname: 'ВИРГИНСКИЕ ОСТРОВА (США)',
      global_id: 273330157,
      fullname: 'Виргинские острова Соединенных Штатов',
      code: 850,
      alf2: 'VI',
      alf3: 'VIR',
    },
    {
      shortname: 'БУРКИНА-ФАСО',
      global_id: 273330158,
      fullname: '',
      code: 854,
      alf2: 'BF',
      alf3: 'BFA',
    },
    {
      shortname: 'УРУГВАЙ',
      global_id: 273330159,
      fullname: 'Восточная Республика Уругвай',
      code: 858,
      alf2: 'UY',
      alf3: 'URY',
    },
    {
      shortname: 'УЗБЕКИСТАН',
      global_id: 273330160,
      fullname: 'Республика Узбекистан',
      code: 860,
      alf2: 'UZ',
      alf3: 'UZB',
    },
    {
      shortname: 'ВЕНЕСУЭЛА',
      global_id: 273330161,
      fullname: 'Боливарианская Республика Венесуэла',
      code: 862,
      alf2: 'VE',
      alf3: 'VEN',
    },
    {
      shortname: 'САМОА',
      global_id: 273330162,
      fullname: 'Независимое Государство Самоа',
      code: 882,
      alf2: 'WS',
      alf3: 'WSM',
    },
    {
      shortname: 'ЙЕМЕН',
      global_id: 273330163,
      fullname: 'Йеменская Республика',
      code: 887,
      alf2: 'YE',
      alf3: 'YEM',
    },
    {
      shortname: 'ЗАМБИЯ',
      global_id: 273330164,
      fullname: 'Республика Замбия',
      code: 894,
      alf2: 'ZM',
      alf3: 'ZMB',
    },
    {
      shortname: 'АБХАЗИЯ',
      global_id: 273330165,
      fullname: 'Республика Абхазия',
      code: 895,
      alf2: 'AB',
      alf3: 'ABH',
    },
    {
      shortname: 'ЮЖНАЯ ОСЕТИЯ',
      global_id: 273330166,
      fullname: 'Республика Южная Осетия',
      code: 896,
      alf2: 'OS',
      alf3: 'OST',
    },
    {
      shortname: 'АФГАНИСТАН',
      global_id: 273330167,
      fullname: 'Переходное Исламское Государство Афганистан',
      code: 4,
      alf2: 'AF',
      alf3: 'AFG',
    },
    {
      shortname: 'АЛБАНИЯ',
      global_id: 273330168,
      fullname: 'Республика Албания',
      code: 8,
      alf2: 'AL',
      alf3: 'ALB',
    },
    {
      shortname: 'АНТАРКТИДА',
      global_id: 273330169,
      fullname: '',
      code: 10,
      alf2: 'AQ',
      alf3: 'ATA',
    },
    {
      shortname: 'АЛЖИР',
      global_id: 273330170,
      fullname: 'Алжирская Народная Демократическая Республика',
      code: 12,
      alf2: 'DZ',
      alf3: 'DZA',
    },
    {
      shortname: 'АМЕРИКАНСКОЕ САМОА',
      global_id: 273330171,
      fullname: '',
      code: 16,
      alf2: 'AS',
      alf3: 'ASM',
    },
    {
      shortname: 'АНДОРРА',
      global_id: 273330172,
      fullname: 'Княжество Андорра',
      code: 20,
      alf2: 'AD',
      alf3: 'AND',
    },
    {
      shortname: 'АНГОЛА',
      global_id: 273330173,
      fullname: 'Республика Ангола',
      code: 24,
      alf2: 'AO',
      alf3: 'AGO',
    },
    {
      shortname: 'АНТИГУА И БАРБУДА',
      global_id: 273330174,
      fullname: '',
      code: 28,
      alf2: 'AG',
      alf3: 'ATG',
    },
    {
      shortname: 'АЗЕРБАЙДЖАН',
      global_id: 273330175,
      fullname: 'Республика Азербайджан',
      code: 31,
      alf2: 'AZ',
      alf3: 'AZE',
    },
    {
      shortname: 'АРГЕНТИНА',
      global_id: 273330176,
      fullname: 'Аргентинская Республика',
      code: 32,
      alf2: 'AR',
      alf3: 'ARG',
    },
    {
      shortname: 'АВСТРАЛИЯ',
      global_id: 273330177,
      fullname: '',
      code: 36,
      alf2: 'AU',
      alf3: 'AUS',
    },
    {
      shortname: 'БАГАМЫ',
      global_id: 273330178,
      fullname: 'Содружество Багамы',
      code: 44,
      alf2: 'BS',
      alf3: 'BHS',
    },
    {
      shortname: 'БАХРЕЙН',
      global_id: 273330179,
      fullname: 'Королевство Бахрейн',
      code: 48,
      alf2: 'BH',
      alf3: 'BHR',
    },
    {
      shortname: 'БАНГЛАДЕШ',
      global_id: 273330180,
      fullname: 'Народная Республика Бангладеш',
      code: 50,
      alf2: 'BD',
      alf3: 'BGD',
    },
    {
      shortname: 'АРМЕНИЯ',
      global_id: 273330181,
      fullname: 'Республика Армения',
      code: 51,
      alf2: 'AM',
      alf3: 'ARM',
    },
    {
      shortname: 'БАРБАДОС',
      global_id: 273330182,
      fullname: '',
      code: 52,
      alf2: 'BB',
      alf3: 'BRB',
    },
    {
      shortname: 'БЕЛЬГИЯ',
      global_id: 273330183,
      fullname: 'Королевство Бельгии',
      code: 56,
      alf2: 'BE',
      alf3: 'BEL',
    },
    {
      shortname: 'БЕРМУДЫ',
      global_id: 273330184,
      fullname: '',
      code: 60,
      alf2: 'BM',
      alf3: 'BMU',
    },
    {
      shortname: 'БУТАН',
      global_id: 273330185,
      fullname: 'Королевство Бутан',
      code: 64,
      alf2: 'BT',
      alf3: 'BTN',
    },
    {
      shortname: 'БОЛИВИЯ',
      global_id: 273330186,
      fullname: 'Многонациональное Государство Боливия',
      code: 68,
      alf2: 'BO',
      alf3: 'BOL',
    },
    {
      shortname: 'БОСНИЯ И ГЕРЦЕГОВИНА',
      global_id: 273330187,
      fullname: '',
      code: 70,
      alf2: 'BA',
      alf3: 'BIH',
    },
    {
      shortname: 'БОТСВАНА',
      global_id: 273330188,
      fullname: 'Республика Ботсвана',
      code: 72,
      alf2: 'BW',
      alf3: 'BWA',
    },
    {
      shortname: 'ОСТРОВ БУВЕ',
      global_id: 273330189,
      fullname: '',
      code: 74,
      alf2: 'BV',
      alf3: 'BVT',
    },
    {
      shortname: 'БРАЗИЛИЯ',
      global_id: 273330190,
      fullname: 'Федеративная Республика Бразилия',
      code: 76,
      alf2: 'BR',
      alf3: 'BRA',
    },
    {
      shortname: 'БЕЛИЗ',
      global_id: 273330191,
      fullname: '',
      code: 84,
      alf2: 'BZ',
      alf3: 'BLZ',
    },
    {
      shortname: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
      global_id: 273330192,
      fullname: '',
      code: 86,
      alf2: 'IO',
      alf3: 'IOT',
    },
    {
      shortname: 'СОЛОМОНОВЫ ОСТРОВА',
      global_id: 273330193,
      fullname: '',
      code: 90,
      alf2: 'SB',
      alf3: 'SLB',
    },
    {
      shortname: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)',
      global_id: 273330194,
      fullname: 'Британские Виргинские острова',
      code: 92,
      alf2: 'VG',
      alf3: 'VGB',
    },
    {
      shortname: 'БРУНЕЙ-ДАРУССАЛАМ',
      global_id: 273330195,
      fullname: '',
      code: 96,
      alf2: 'BN',
      alf3: 'BRN',
    },
    {
      shortname: 'БОЛГАРИЯ',
      global_id: 273330196,
      fullname: 'Республика Болгария',
      code: 100,
      alf2: 'BG',
      alf3: 'BGR',
    },
    {
      shortname: 'МЬЯНМА',
      global_id: 273330197,
      fullname: 'Республика Союза Мьянма',
      code: 104,
      alf2: 'MM',
      alf3: 'MMR',
    },
    {
      shortname: 'БУРУНДИ',
      global_id: 273330198,
      fullname: 'Республика Бурунди',
      code: 108,
      alf2: 'BI',
      alf3: 'BDI',
    },
    {
      shortname: 'БЕЛАРУСЬ',
      global_id: 273330199,
      fullname: 'Республика Беларусь',
      code: 112,
      alf2: 'BY',
      alf3: 'BLR',
    },
    {
      shortname: 'КАМБОДЖА',
      global_id: 273330200,
      fullname: 'Королевство Камбоджа',
      code: 116,
      alf2: 'KH',
      alf3: 'KHM',
    },
    {
      shortname: 'КАМЕРУН',
      global_id: 273330201,
      fullname: 'Республика Камерун',
      code: 120,
      alf2: 'CM',
      alf3: 'CMR',
    },
    {
      shortname: 'КАНАДА',
      global_id: 273330202,
      fullname: '',
      code: 124,
      alf2: 'CA',
      alf3: 'CAN',
    },
    {
      shortname: 'КАБО-ВЕРДЕ',
      global_id: 273330203,
      fullname: 'Республика Кабо-Верде',
      code: 132,
      alf2: 'CV',
      alf3: 'CPV',
    },
    {
      shortname: 'ОСТРОВА КАЙМАН',
      global_id: 273330204,
      fullname: '',
      code: 136,
      alf2: 'KY',
      alf3: 'CYM',
    },
    {
      shortname: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
      global_id: 273330205,
      fullname: '',
      code: 140,
      alf2: 'CF',
      alf3: 'CAF',
    },
    {
      shortname: 'ШРИ-ЛАНКА',
      global_id: 273330206,
      fullname: 'Демократическая Социалистическая Республика Шри-Ланка',
      code: 144,
      alf2: 'LK',
      alf3: 'LKA',
    },
    {
      shortname: 'ЧИЛИ',
      global_id: 273330207,
      fullname: 'Республика Чили',
      code: 152,
      alf2: 'CL',
      alf3: 'CHL',
    },
    {
      shortname: 'КИТАЙ',
      global_id: 273330208,
      fullname: 'Китайская Народная Республика',
      code: 156,
      alf2: 'CN',
      alf3: 'CHN',
    },
    {
      shortname: 'ТАЙВАНЬ (КИТАЙ)',
      global_id: 273330209,
      fullname: '',
      code: 158,
      alf2: 'TW',
      alf3: 'TWN',
    },
    {
      shortname: 'ОСТРОВ РОЖДЕСТВА',
      global_id: 273330210,
      fullname: '',
      code: 162,
      alf2: 'CX',
      alf3: 'CXR',
    },
    {
      shortname: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
      global_id: 273330211,
      fullname: '',
      code: 166,
      alf2: 'CC',
      alf3: 'CCK',
    },
    {
      shortname: 'КОЛУМБИЯ',
      global_id: 273330212,
      fullname: 'Республика Колумбия',
      code: 170,
      alf2: 'CO',
      alf3: 'COL',
    },
    {
      shortname: 'МАЙОТТА',
      global_id: 273330213,
      fullname: '',
      code: 175,
      alf2: 'YT',
      alf3: 'MYT',
    },
    {
      shortname: 'КОНГО',
      global_id: 273330214,
      fullname: 'Республика Конго',
      code: 178,
      alf2: 'CG',
      alf3: 'COG',
    },
    {
      shortname: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
      global_id: 273330215,
      fullname: 'Демократическая Республика Конго',
      code: 180,
      alf2: 'CD',
      alf3: 'COD',
    },
    {
      shortname: 'ОСТРОВА КУКА',
      global_id: 273330216,
      fullname: '',
      code: 184,
      alf2: 'CK',
      alf3: 'COK',
    },
    {
      shortname: 'КОСТА-РИКА',
      global_id: 273330217,
      fullname: 'Республика Коста-Рика',
      code: 188,
      alf2: 'CR',
      alf3: 'CRI',
    },
    {
      shortname: 'ХОРВАТИЯ',
      global_id: 273330218,
      fullname: 'Республика Хорватия',
      code: 191,
      alf2: 'HR',
      alf3: 'HRV',
    },
    {
      shortname: 'КУБА',
      global_id: 273330219,
      fullname: 'Республика Куба',
      code: 192,
      alf2: 'CU',
      alf3: 'CUB',
    },
    {
      shortname: 'КИПР',
      global_id: 273330220,
      fullname: 'Республика Кипр',
      code: 196,
      alf2: 'CY',
      alf3: 'CYP',
    },
    {
      shortname: 'ЧЕХИЯ',
      global_id: 273330221,
      fullname: 'Чешская Республика',
      code: 203,
      alf2: 'CZ',
      alf3: 'CZE',
    },
    {
      shortname: 'БЕНИН',
      global_id: 273330222,
      fullname: 'Республика Бенин',
      code: 204,
      alf2: 'BJ',
      alf3: 'BEN',
    },
    {
      shortname: 'ДАНИЯ',
      global_id: 273330223,
      fullname: 'Королевство Дания',
      code: 208,
      alf2: 'DK',
      alf3: 'DNK',
    },
    {
      shortname: 'ДОМИНИКА',
      global_id: 273330224,
      fullname: 'Содружество Доминики',
      code: 212,
      alf2: 'DM',
      alf3: 'DMA',
    },
    {
      shortname: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
      global_id: 273330225,
      fullname: '',
      code: 214,
      alf2: 'DO',
      alf3: 'DOM',
    },
    {
      shortname: 'ЭКВАДОР',
      global_id: 273330226,
      fullname: 'Республика Эквадор',
      code: 218,
      alf2: 'EC',
      alf3: 'ECU',
    },
    {
      shortname: 'ЭЛЬ-САЛЬВАДОР',
      global_id: 273330227,
      fullname: 'Республика Эль-Сальвадор',
      code: 222,
      alf2: 'SV',
      alf3: 'SLV',
    },
    {
      shortname: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
      global_id: 273330228,
      fullname: 'Республика Экваториальная Гвинея',
      code: 226,
      alf2: 'GQ',
      alf3: 'GNQ',
    },
    {
      shortname: 'ЭФИОПИЯ',
      global_id: 273330229,
      fullname: 'Федеративная Демократическая Республика Эфиопия',
      code: 231,
      alf2: 'ET',
      alf3: 'ETH',
    },
    {
      shortname: 'ЭРИТРЕЯ',
      global_id: 273330230,
      fullname: 'Государство Эритрея',
      code: 232,
      alf2: 'ER',
      alf3: 'ERI',
    },
    {
      shortname: 'ЭСТОНИЯ',
      global_id: 273330231,
      fullname: 'Эстонская Республика',
      code: 233,
      alf2: 'EE',
      alf3: 'EST',
    },
    {
      shortname: 'ФАРЕРСКИЕ ОСТРОВА',
      global_id: 273330232,
      fullname: '',
      code: 234,
      alf2: 'FO',
      alf3: 'FRO',
    },
    {
      shortname: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
      global_id: 273330233,
      fullname: '',
      code: 238,
      alf2: 'FK',
      alf3: 'FLK',
    },
    {
      shortname: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
      global_id: 273330234,
      fullname: '',
      code: 239,
      alf2: 'GS',
      alf3: 'SGS',
    },
    {
      shortname: 'ФИДЖИ',
      global_id: 273330235,
      fullname: 'Республика Фиджи',
      code: 242,
      alf2: 'FJ',
      alf3: 'FJI',
    },
    {
      shortname: 'ФИНЛЯНДИЯ',
      global_id: 273330236,
      fullname: 'Финляндская Республика',
      code: 246,
      alf2: 'FI',
      alf3: 'FIN',
    },
    {
      shortname: 'ЭЛАНДСКИЕ ОСТРОВА',
      global_id: 273330237,
      fullname: '',
      code: 248,
      alf2: 'AX',
      alf3: 'ALA',
    },
    {
      shortname: 'ФРАНЦИЯ',
      global_id: 273330238,
      fullname: 'Французская Республика',
      code: 250,
      alf2: 'FR',
      alf3: 'FRA',
    },
    {
      shortname: 'ФРАНЦУЗСКАЯ ГВИАНА',
      global_id: 273330239,
      fullname: '',
      code: 254,
      alf2: 'GF',
      alf3: 'GUF',
    },
    {
      shortname: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
      global_id: 273330240,
      fullname: '',
      code: 258,
      alf2: 'PF',
      alf3: 'PYF',
    },
    {
      shortname: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
      global_id: 273330241,
      fullname: '',
      code: 260,
      alf2: 'TF',
      alf3: 'ATF',
    },
    {
      shortname: 'ДЖИБУТИ',
      global_id: 273330242,
      fullname: 'Республика Джибути',
      code: 262,
      alf2: 'DJ',
      alf3: 'DJI',
    },
    {
      shortname: 'ГРУЗИЯ',
      global_id: 273330243,
      fullname: '',
      code: 268,
      alf2: 'GE',
      alf3: 'GEO',
    },
    {
      shortname: 'ГАМБИЯ',
      global_id: 273330244,
      fullname: 'Исламская Республика Гамбия',
      code: 270,
      alf2: 'GM',
      alf3: 'GMB',
    },
    {
      shortname: 'ГЕРМАНИЯ',
      global_id: 273330245,
      fullname: 'Федеративная Республика Германия',
      code: 276,
      alf2: 'DE',
      alf3: 'DEU',
    },
    {
      shortname: 'ГАНА',
      global_id: 273330246,
      fullname: 'Республика Гана',
      code: 288,
      alf2: 'GH',
      alf3: 'GHA',
    },
    {
      shortname: 'ГИБРАЛТАР',
      global_id: 273330247,
      fullname: '',
      code: 292,
      alf2: 'GI',
      alf3: 'GIB',
    },
    {
      shortname: 'КИРИБАТИ',
      global_id: 273330248,
      fullname: 'Республика Кирибати',
      code: 296,
      alf2: 'KI',
      alf3: 'KIR',
    },
    {
      shortname: 'ГРЕЦИЯ',
      global_id: 273330249,
      fullname: 'Греческая Республика',
      code: 300,
      alf2: 'GR',
      alf3: 'GRC',
    },
    {
      shortname: 'ГРЕНЛАНДИЯ',
      global_id: 273330250,
      fullname: '',
      code: 304,
      alf2: 'GL',
      alf3: 'GRL',
    },
    {
      shortname: 'ГРЕНАДА',
      global_id: 273330251,
      fullname: '',
      code: 308,
      alf2: 'GD',
      alf3: 'GRD',
    },
    {
      shortname: 'ГУАМ',
      global_id: 273330252,
      fullname: '',
      code: 316,
      alf2: 'GU',
      alf3: 'GUM',
    },
    {
      shortname: 'ГВАТЕМАЛА',
      global_id: 273330253,
      fullname: 'Республика Гватемала',
      code: 320,
      alf2: 'GT',
      alf3: 'GTM',
    },
    {
      shortname: 'ГВИНЕЯ',
      global_id: 273330254,
      fullname: 'Гвинейская Республика',
      code: 324,
      alf2: 'GN',
      alf3: 'GIN',
    },
    {
      shortname: 'ГАЙАНА',
      global_id: 273330255,
      fullname: 'Кооперативная Республика Гайана',
      code: 328,
      alf2: 'GY',
      alf3: 'GUY',
    },
    {
      shortname: 'ГАИТИ',
      global_id: 273330256,
      fullname: 'Республика Гаити',
      code: 332,
      alf2: 'HT',
      alf3: 'HTI',
    },
    {
      shortname: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
      global_id: 273330257,
      fullname: '',
      code: 334,
      alf2: 'HM',
      alf3: 'HMD',
    },
    {
      shortname: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
      global_id: 273330258,
      fullname: '',
      code: 336,
      alf2: 'VA',
      alf3: 'VAT',
    },
    {
      shortname: 'ГОНКОНГ',
      global_id: 273330259,
      fullname: 'Специальный административный регион Китая Гонконг',
      code: 344,
      alf2: 'HK',
      alf3: 'HKG',
    },
    {
      shortname: 'ВЕНГРИЯ',
      global_id: 273330260,
      fullname: 'Венгрия',
      code: 348,
      alf2: 'HU',
      alf3: 'HUN',
    },
    {
      shortname: 'ИСЛАНДИЯ',
      global_id: 273330261,
      fullname: 'Республика Исландия',
      code: 352,
      alf2: 'IS',
      alf3: 'ISL',
    },
    {
      shortname: 'ИНДИЯ',
      global_id: 273330262,
      fullname: 'Республика Индия',
      code: 356,
      alf2: 'IN',
      alf3: 'IND',
    },
    {
      shortname: 'ИНДОНЕЗИЯ',
      global_id: 273330263,
      fullname: 'Республика Индонезия',
      code: 360,
      alf2: 'ID',
      alf3: 'IDN',
    },
    {
      shortname: 'ИРАК',
      global_id: 273330264,
      fullname: 'Республика Ирак',
      code: 368,
      alf2: 'IQ',
      alf3: 'IRQ',
    },
    {
      shortname: 'ИЗРАИЛЬ',
      global_id: 273330265,
      fullname: 'Государство Израиль',
      code: 376,
      alf2: 'IL',
      alf3: 'ISR',
    },
    {
      shortname: 'ИТАЛИЯ',
      global_id: 273330266,
      fullname: 'Итальянская Республика',
      code: 380,
      alf2: 'IT',
      alf3: 'ITA',
    },
    {
      shortname: 'КОТ Д`ИВУАР',
      global_id: 273330267,
      fullname: 'Республика Кот д`Ивуар',
      code: 384,
      alf2: 'CI',
      alf3: 'CIV',
    },
    {
      shortname: 'ЯМАЙКА',
      global_id: 273330268,
      fullname: '',
      code: 388,
      alf2: 'JM',
      alf3: 'JAM',
    },
    {
      shortname: 'КАЗАХСТАН',
      global_id: 273330269,
      fullname: 'Республика Казахстан',
      code: 398,
      alf2: 'KZ',
      alf3: 'KAZ',
    },
    {
      shortname: 'ИОРДАНИЯ',
      global_id: 273330270,
      fullname: 'Иорданское Хашимитское Королевство',
      code: 400,
      alf2: 'JO',
      alf3: 'JOR',
    },
    {
      shortname: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
      global_id: 273330271,
      fullname: 'Корейская Народно-Демократическая Республика',
      code: 408,
      alf2: 'KP',
      alf3: 'PRK',
    },
    {
      shortname: 'КОРЕЯ, РЕСПУБЛИКА',
      global_id: 273330272,
      fullname: 'Республика Корея',
      code: 410,
      alf2: 'KR',
      alf3: 'KOR',
    },
    {
      shortname: 'КУВЕЙТ',
      global_id: 273330273,
      fullname: 'Государство Кувейт',
      code: 414,
      alf2: 'KW',
      alf3: 'KWT',
    },
    {
      shortname: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
      global_id: 273330274,
      fullname: '',
      code: 418,
      alf2: 'LA',
      alf3: 'LAO',
    },
    {
      shortname: 'ЛИВАН',
      global_id: 273330275,
      fullname: 'Ливанская Республика',
      code: 422,
      alf2: 'LB',
      alf3: 'LBN',
    },
    {
      shortname: 'ЛЕСОТО',
      global_id: 273330276,
      fullname: 'Королевство Лесото',
      code: 426,
      alf2: 'LS',
      alf3: 'LSO',
    },
    {
      shortname: 'ЛАТВИЯ',
      global_id: 273330277,
      fullname: 'Латвийская Республика',
      code: 428,
      alf2: 'LV',
      alf3: 'LVA',
    },
    {
      shortname: 'ЛИБЕРИЯ',
      global_id: 273330278,
      fullname: 'Республика Либерия',
      code: 430,
      alf2: 'LR',
      alf3: 'LBR',
    },
    {
      shortname: 'ЛИВИЯ',
      global_id: 273330279,
      fullname: 'Государство Ливия',
      code: 434,
      alf2: 'LY',
      alf3: 'LBY',
    },
    {
      shortname: 'ЛИХТЕНШТЕЙН',
      global_id: 273330280,
      fullname: 'Княжество Лихтенштейн',
      code: 438,
      alf2: 'LI',
      alf3: 'LIE',
    },
    {
      shortname: 'ЛИТВА',
      global_id: 273330281,
      fullname: 'Литовская Республика',
      code: 440,
      alf2: 'LT',
      alf3: 'LTU',
    },
    {
      shortname: 'ЛЮКСЕМБУРГ',
      global_id: 273330282,
      fullname: 'Великое Герцогство Люксембург',
      code: 442,
      alf2: 'LU',
      alf3: 'LUX',
    },
    {
      shortname: 'МАКАО',
      global_id: 273330283,
      fullname: 'Специальный административный регион Китая Макао',
      code: 446,
      alf2: 'MO',
      alf3: 'MAC',
    },
    {
      shortname: 'МАДАГАСКАР',
      global_id: 273330284,
      fullname: 'Республика Мадагаскар',
      code: 450,
      alf2: 'MG',
      alf3: 'MDG',
    },
    {
      shortname: 'МАЛАВИ',
      global_id: 273330285,
      fullname: 'Республика Малави',
      code: 454,
      alf2: 'MW',
      alf3: 'MWI',
    },
    {
      shortname: 'МАЛАЙЗИЯ',
      global_id: 273330286,
      fullname: '',
      code: 458,
      alf2: 'MY',
      alf3: 'MYS',
    },
    {
      shortname: 'МАЛЬДИВЫ',
      global_id: 273330287,
      fullname: 'Мальдивская Республика',
      code: 462,
      alf2: 'MV',
      alf3: 'MDV',
    },
    {
      shortname: 'МАЛИ',
      global_id: 273330288,
      fullname: 'Республика Мали',
      code: 466,
      alf2: 'ML',
      alf3: 'MLI',
    },
    {
      shortname: 'МАРТИНИКА',
      global_id: 273330289,
      fullname: '',
      code: 474,
      alf2: 'MQ',
      alf3: 'MTQ',
    },
    {
      shortname: 'МАВРИТАНИЯ',
      global_id: 273330290,
      fullname: 'Исламская Республика Мавритания',
      code: 478,
      alf2: 'MR',
      alf3: 'MRT',
    },
    {
      shortname: 'МАВРИКИЙ',
      global_id: 273330291,
      fullname: 'Республика Маврикий',
      code: 480,
      alf2: 'MU',
      alf3: 'MUS',
    },
    {
      shortname: 'МЕКСИКА',
      global_id: 273330292,
      fullname: 'Мексиканские Соединенные Штаты',
      code: 484,
      alf2: 'MX',
      alf3: 'MEX',
    },
    {
      shortname: 'МОНАКО',
      global_id: 273330293,
      fullname: 'Княжество Монако',
      code: 492,
      alf2: 'MC',
      alf3: 'MCO',
    },
    {
      shortname: 'МОНГОЛИЯ',
      global_id: 273330294,
      fullname: '',
      code: 496,
      alf2: 'MN',
      alf3: 'MNG',
    },
    {
      shortname: 'МОЛДОВА, РЕСПУБЛИКА',
      global_id: 273330295,
      fullname: 'Республика Молдова',
      code: 498,
      alf2: 'MD',
      alf3: 'MDA',
    },
    {
      shortname: 'ЧЕРНОГОРИЯ',
      global_id: 273330296,
      fullname: '',
      code: 499,
      alf2: 'ME',
      alf3: 'MNE',
    },
    {
      shortname: 'МОНТСЕРРАТ',
      global_id: 273330297,
      fullname: '',
      code: 500,
      alf2: 'MS',
      alf3: 'MSR',
    },
    {
      shortname: 'МАРОККО',
      global_id: 273330298,
      fullname: 'Королевство Марокко',
      code: 504,
      alf2: 'MA',
      alf3: 'MAR',
    },
    {
      shortname: 'ОМАН',
      global_id: 273330299,
      fullname: 'Султанат Оман',
      code: 512,
      alf2: 'OM',
      alf3: 'OMN',
    },
    {
      shortname: 'НАМИБИЯ',
      global_id: 273330300,
      fullname: 'Республика Намибия',
      code: 516,
      alf2: 'NA',
      alf3: 'NAM',
    },
    {
      shortname: 'НАУРУ',
      global_id: 273330301,
      fullname: 'Республика Науру',
      code: 520,
      alf2: 'NR',
      alf3: 'NRU',
    },
    {
      shortname: 'НЕПАЛ',
      global_id: 273330302,
      fullname: 'Федеративная Демократическая Республика Непал',
      code: 524,
      alf2: 'NP',
      alf3: 'NPL',
    },
    {
      shortname: 'НИДЕРЛАНДЫ',
      global_id: 273330303,
      fullname: 'Королевство Нидерландов',
      code: 528,
      alf2: 'NL',
      alf3: 'NLD',
    },
    {
      shortname: 'КЮРАСАО',
      global_id: 273330304,
      fullname: '',
      code: 531,
      alf2: 'CW',
      alf3: 'CUW',
    },
    {
      shortname: 'СЕН-МАРТЕН (нидерландская часть)',
      global_id: 273330305,
      fullname: '',
      code: 534,
      alf2: 'SX',
      alf3: 'SXM',
    },
    {
      shortname: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
      global_id: 273330306,
      fullname: '',
      code: 535,
      alf2: 'BQ',
      alf3: 'BES',
    },
    {
      shortname: 'ВАНУАТУ',
      global_id: 273330307,
      fullname: 'Республика Вануату',
      code: 548,
      alf2: 'VU',
      alf3: 'VUT',
    },
    {
      shortname: 'НОВАЯ ЗЕЛАНДИЯ',
      global_id: 273330308,
      fullname: '',
      code: 554,
      alf2: 'NZ',
      alf3: 'NZL',
    },
    {
      shortname: 'НИКАРАГУА',
      global_id: 273330309,
      fullname: 'Республика Никарагуа',
      code: 558,
      alf2: 'NI',
      alf3: 'NIC',
    },
    {
      shortname: 'НИГЕР',
      global_id: 273330310,
      fullname: 'Республика Нигер',
      code: 562,
      alf2: 'NE',
      alf3: 'NER',
    },
    {
      shortname: 'НИГЕРИЯ',
      global_id: 273330311,
      fullname: 'Федеративная Республика Нигерия',
      code: 566,
      alf2: 'NG',
      alf3: 'NGA',
    },
    {
      shortname: 'НИУЭ',
      global_id: 273330312,
      fullname: 'Ниуэ',
      code: 570,
      alf2: 'NU',
      alf3: 'NIU',
    },
    {
      shortname: 'ОСТРОВ НОРФОЛК',
      global_id: 273330313,
      fullname: '',
      code: 574,
      alf2: 'NF',
      alf3: 'NFK',
    },
    {
      shortname: 'НОРВЕГИЯ',
      global_id: 273330314,
      fullname: 'Королевство Норвегия',
      code: 578,
      alf2: 'NO',
      alf3: 'NOR',
    },
    {
      shortname: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
      global_id: 273330315,
      fullname: 'Содружество Северных Марианских островов',
      code: 580,
      alf2: 'MP',
      alf3: 'MNP',
    },
    {
      shortname: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
      global_id: 273330316,
      fullname: '',
      code: 581,
      alf2: 'UM',
      alf3: 'UMI',
    },
    {
      shortname: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
      global_id: 273330317,
      fullname: 'Федеративные Штаты Микронезии',
      code: 583,
      alf2: 'FM',
      alf3: 'FSM',
    },
    {
      shortname: 'МАРШАЛЛОВЫ ОСТРОВА',
      global_id: 273330318,
      fullname: 'Республика Маршалловы Острова',
      code: 584,
      alf2: 'MH',
      alf3: 'MHL',
    },
    {
      shortname: 'ПАЛАУ',
      global_id: 273330319,
      fullname: 'Республика Палау',
      code: 585,
      alf2: 'PW',
      alf3: 'PLW',
    },
    {
      shortname: 'ПАКИСТАН',
      global_id: 273330320,
      fullname: 'Исламская Республика Пакистан',
      code: 586,
      alf2: 'PK',
      alf3: 'PAK',
    },
    {
      shortname: 'ПАНАМА',
      global_id: 273330321,
      fullname: 'Республика Панама',
      code: 591,
      alf2: 'PA',
      alf3: 'PAN',
    },
    {
      shortname: 'ПАПУА НОВАЯ ГВИНЕЯ',
      global_id: 273330322,
      fullname: 'Независимое Государство Папуа Новая Гвинея',
      code: 598,
      alf2: 'PG',
      alf3: 'PNG',
    },
    {
      shortname: 'ПАРАГВАЙ',
      global_id: 273330323,
      fullname: 'Республика Парагвай',
      code: 600,
      alf2: 'PY',
      alf3: 'PRY',
    },
    {
      shortname: 'ПЕРУ',
      global_id: 273330324,
      fullname: 'Республика Перу',
      code: 604,
      alf2: 'PE',
      alf3: 'PER',
    },
    {
      shortname: 'ФИЛИППИНЫ',
      global_id: 273330325,
      fullname: 'Республика Филиппины',
      code: 608,
      alf2: 'PH',
      alf3: 'PHL',
    },
    {
      shortname: 'ПИТКЕРН',
      global_id: 273330326,
      fullname: '',
      code: 612,
      alf2: 'PN',
      alf3: 'PCN',
    },
    {
      shortname: 'ПОЛЬША',
      global_id: 273330327,
      fullname: 'Республика Польша',
      code: 616,
      alf2: 'PL',
      alf3: 'POL',
    },
    {
      shortname: 'ПОРТУГАЛИЯ',
      global_id: 273330328,
      fullname: 'Португальская Республика',
      code: 620,
      alf2: 'PT',
      alf3: 'PRT',
    },
    {
      shortname: 'ГВИНЕЯ-БИСАУ',
      global_id: 273330329,
      fullname: 'Республика Гвинея-Бисау',
      code: 624,
      alf2: 'GW',
      alf3: 'GNB',
    },
    {
      shortname: 'ТИМОР-ЛЕСТЕ',
      global_id: 273330330,
      fullname: 'Демократическая Республика Тимор-Лесте',
      code: 626,
      alf2: 'TL',
      alf3: 'TLS',
    },
    {
      shortname: 'ПУЭРТО-РИКО',
      global_id: 273330331,
      fullname: '',
      code: 630,
      alf2: 'PR',
      alf3: 'PRI',
    },
    {
      shortname: 'КАТАР',
      global_id: 273330332,
      fullname: 'Государство Катар',
      code: 634,
      alf2: 'QA',
      alf3: 'QAT',
    },
    {
      shortname: 'РЕЮНЬОН',
      global_id: 273330333,
      fullname: '',
      code: 638,
      alf2: 'RE',
      alf3: 'REU',
    },
    {
      shortname: 'РУМЫНИЯ',
      global_id: 273330334,
      fullname: '',
      code: 642,
      alf2: 'RO',
      alf3: 'ROU',
    },
    {
      shortname: 'РОССИЯ',
      global_id: 273330335,
      fullname: 'Российская Федерация',
      code: 643,
      alf2: 'RU',
      alf3: 'RUS',
    },
    {
      shortname: 'РУАНДА',
      global_id: 273330336,
      fullname: 'Руандийская Республика',
      code: 646,
      alf2: 'RW',
      alf3: 'RWA',
    },
    {
      shortname: 'СЕН-БАРТЕЛЕМИ',
      global_id: 273330337,
      fullname: '',
      code: 652,
      alf2: 'BL',
      alf3: 'BLM',
    },
    {
      shortname: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
      global_id: 273330338,
      fullname: '',
      code: 654,
      alf2: 'SH',
      alf3: 'SHN',
    },
    {
      shortname: 'СЕНТ-КИТС И НЕВИС',
      global_id: 273330339,
      fullname: '',
      code: 659,
      alf2: 'KN',
      alf3: 'KNA',
    },
    {
      shortname: 'АНГИЛЬЯ',
      global_id: 273330340,
      fullname: '',
      code: 660,
      alf2: 'AI',
      alf3: 'AIA',
    },
    {
      shortname: 'СЕНТ-ЛЮСИЯ',
      global_id: 273330341,
      fullname: '',
      code: 662,
      alf2: 'LC',
      alf3: 'LCA',
    },
    {
      shortname: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)',
      global_id: 273330342,
      fullname: '',
      code: 663,
      alf2: 'MF',
      alf3: 'MAF',
    },
    {
      shortname: 'СЕНТ-ПЬЕР И МИКЕЛОН',
      global_id: 273330343,
      fullname: '',
      code: 666,
      alf2: 'PM',
      alf3: 'SPM',
    },
    {
      shortname: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
      global_id: 273330344,
      fullname: '',
      code: 670,
      alf2: 'VC',
      alf3: 'VCT',
    },
    {
      shortname: 'САН-МАРИНО',
      global_id: 273330345,
      fullname: 'Республика Сан-Марино',
      code: 674,
      alf2: 'SM',
      alf3: 'SMR',
    },
    {
      shortname: 'САН-ТОМЕ И ПРИНСИПИ',
      global_id: 273330346,
      fullname: 'Демократическая Республика Сан-Томе и Принсипи',
      code: 678,
      alf2: 'ST',
      alf3: 'STP',
    },
    {
      shortname: 'САУДОВСКАЯ АРАВИЯ',
      global_id: 273330347,
      fullname: 'Королевство Саудовская Аравия',
      code: 682,
      alf2: 'SA',
      alf3: 'SAU',
    },
    {
      shortname: 'СЕНЕГАЛ',
      global_id: 273330348,
      fullname: 'Республика Сенегал',
      code: 686,
      alf2: 'SN',
      alf3: 'SEN',
    },
    {
      shortname: 'СЕРБИЯ',
      global_id: 273330349,
      fullname: 'Республика Сербия',
      code: 688,
      alf2: 'RS',
      alf3: 'SRB',
    },
    {
      shortname: 'СЕЙШЕЛЫ',
      global_id: 273330350,
      fullname: 'Республика Сейшелы',
      code: 690,
      alf2: 'SC',
      alf3: 'SYC',
    },
    {
      shortname: 'СЬЕРРА-ЛЕОНЕ',
      global_id: 273330351,
      fullname: 'Республика Сьерра-Леоне',
      code: 694,
      alf2: 'SL',
      alf3: 'SLE',
    },
  ]
  return countryArray.map((i) => {
    return {
      shortname: i.shortname,
      global_id: i.global_id,
      fullname: i.fullname,
      code: i.code.toString(),
      alf2: i.alf2,
      alf3: i.alf3,
    }
  })
}
