<script setup lang="ts">
import useTextFilters from '@/plugins/TextFilters'
import { Document } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  name: string
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'close'): void
}

const emit = defineEmits<IEmits>()
const { subStringFileName } = useTextFilters()
const { colors, isDark } = useColors()
</script>

<template>
  <div class="mr-2 my-2 font-weight--semi">
    <el-tooltip effect="dark" :content="name" :disabled="name.length > 20" placement="top">
      <el-tag class="flex justify-between" size="large" type="primary" closable @close="emit('close')">
        <div class="flex items-center">
          <el-icon class="mr-2" size="18" :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple">
            <Document />
          </el-icon>
          <p>{{ subStringFileName(props.name, 40) }}</p>
        </div>
      </el-tag>
    </el-tooltip>
  </div>
</template>

<style lang="sass" scoped></style>
