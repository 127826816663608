import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "mx-4" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_4 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_5 = { class: "flex items-center flex-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_7 = { key: 1 }

import { IAddService } from '@/entityes/invoice/invoice.type'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import { computed, ref } from 'vue'
import { SuccessFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import useFileDownload from '@/use/useFileDownload'
import { transformFileFromServer } from '@/06-Entities/File'
import useTypeWriter from '@/plugins/useTypeWriter'

interface IProps {
  invoiceId: string | number
  services: IAddService[] | undefined
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddIncomesFiles',
  props: {
    invoiceId: {},
    services: {}
  },
  setup(__props: any) {

const props = __props
const { incomesDeductionsDescribers: describer } = AddIncomesRefundsDescriber()
const { colors, isDark } = useColors()
const { downloadServiceFile } = useFileDownload()
const activeNames = ref('')
const { renderString } = useTypeWriter('загрузка...')
const renderServices = computed(() => {
  return props.services?.filter(
    (service) =>
      !['guru_file', 'declaration_pdf', 'cfr_pdf', 'tax_plan', 'personal_manager'].includes(service.service_type)
  )
})
function downLoadFile(file: FileFromServer) {
  file.isLoading = true
  const params = {
    invoiceId: props.invoiceId,
    serviceId: props.services?.find((service) => service.service_type == file.service_type)?.id,
    file,
  }
  downloadServiceFile(params).finally(() => (file.isLoading = false))
}

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_collapse, {
      modelValue: activeNames.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeNames).value = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderServices.value, (service) => {
          return (_openBlock(), _createBlock(_component_el_collapse_item, {
            name: service.service_type
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(describer).find((i) => i.tag === service.service_type)?.name), 1),
                (service.files.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_el_icon, {
                        class: "mr-1",
                        color: _unref(isDark) ? _unref(colors).dark.ng_green : _unref(colors).light.ng_green
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(SuccessFilled))
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "dark:text-dark-green text-light-green" }, "Файлы загружены", -1))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_el_icon, {
                        class: "mr-1",
                        color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(SuccessFilled))
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "dark:text-dark-red text-light-red" }, "Файлы не загружены", -1))
                    ]))
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.files.map((i) => _unref(transformFileFromServer)(i)), (file) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    _createVNode(_component_el_tooltip, {
                      effect: _unref(isDark) ? 'light' : 'dark',
                      content: `скачать ${file.serverName}`,
                      placement: "bottom"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tag, {
                          effect: "light",
                          class: "cursor-point min-h-[30px] w-[260px] card-hover m-2",
                          size: "small",
                          type: "primary",
                          onClick: ($event: any) => (downLoadFile(file))
                        }, {
                          default: _withCtx(() => [
                            (file.isLoading)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                  _createVNode(_component_el_icon, {
                                    class: "el-icon-loading font-weight--extra mr-2",
                                    style: {"vertical-align":"middle"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Loading)
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("p", null, _toDisplayString(_unref(renderString)), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createElementVNode("p", null, _toDisplayString(file.name), 1)
                                ]))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["effect", "content"])
                  ]))
                }), 256))
              ])
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 256))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})