<script setup lang="ts">
import { IInvoice, InvoiceTariffType } from '@/entityes/invoice/invoice.type'
import Tariffs from '@/04-Widgets/GoodsSelection/ui/Tariffs.vue'
import { computed, ComputedRef, reactive, ref, watch } from 'vue'
import useGoodsDescription, { IGood } from '@/describers/GoodsDescriber'
import GoodCard from '@/04-Widgets/GoodsSelection/ui/GoodCard.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import YearSelect from '@/04-Widgets/GoodsSelection/ui/YearSelect.vue'
import usePolitic from '@/use/usePolitic'
import Basket from '@/04-Widgets/GoodsSelection/ui/Basket.vue'
import { CreditCard, Discount } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import FinishPaymentDialog from '@/components/Admin/FinishPaymentDialog.vue'
import { ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'
import useGtagManager from '@/use/useGtagManager'
import PromocodeForm from '@/04-Widgets/GoodsSelection/ui/PromocodeForm.vue'
import OTO from '@/04-Widgets/GoodsSelection/ui/OTO.vue'
import DiscountRequest from '@/components/DiscountRequest.vue'
import useTextFilters from '@/plugins/TextFilters'
import SplitWindow from '@/07-Shared/ui/SplitWindow.vue'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'
import { IPaymentBody } from '@/entityes/invoice/invoice.type'

interface IProps {
  invoice: IInvoice
}

const props = defineProps<IProps>()
const { isAdmin, isCustomer } = usePolitic()
const { goodSelect, getInvoice, surchargePayment } = useInvoiceRepository()
const { event } = useGtagManager()
const { goodsList } = useGoodsDescription()
const { numberSeparator } = useTextFilters()

const surcharge = computed(() => props.invoice?.surcharge || 0)
const tariff = ref(props.invoice?.tariff || 'base')
const goods = computed(() => {
  // TMP: temporary decision
  if (hasFFGlobal.value && tariff.value === 'minimal') {
    return goodsList.filter((j) => j.showGood && !['Cfr', 'cfr_pdf'].includes(j.name))
  } else {
    return goodsList.filter((i) => i.showGood)
  }
})
const baseYearsArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024]
const years = reactive([...baseYearsArray])
const selectedYear = ref(props.invoice.year_to_calc)
const selectedCountry = ref('ru')
const brokerFiles = computed(() => {
  const files: Partial<Record<BrokerFileType, { id: number; name: string }[]>> | undefined = props.invoice?.broker_files
  if (!files) return []
  const keys = Object.keys(files) as BrokerFileType[]

  return keys.filter((key) => {
    const fileArray = files[key]
    return !!fileArray && fileArray.length > 0
  })
})
const total = ref(0)
const loading = ref(false)
const router = useRouter()
const discountRequest = ref<any | null>(null)
const basketEmpty = computed(() => goods.value.filter((i) => i.inBasket.value).length === 0)

const tinkoffPremiumLink = computed(() => props.invoice?.payments?.link)
const tinkoffPremiumInvoice = computed(() => props.invoice?.email === 'external_premium@tinkoff.tinkoff')

const hasBucket = computed(() => brokerFiles.value.includes('bucket_files'))
const hasFFGlobal = computed(() => brokerFiles.value.includes('freedom_finance_global_files'))
const hasDeclarationPdf = computed(() => goods.value.find((i) => i.name === 'declaration_pdf')?.inBasket.value)
const hasCfrPdf = computed(() => goods.value.find((i) => i.name === 'cfr_pdf')?.inBasket.value)
const hasTaxPlan = computed(() => goods.value.find((i) => i.name === 'tax_plan')?.inBasket.value)

const services = computed(() => ({
  ...props.invoice.pre_selected_add_incomes,
  ...(hasDeclarationPdf.value ? { declaration_pdf: 1 } : {}),
  ...(hasCfrPdf.value ? { cfr_pdf: 1 } : {}),
  ...(hasTaxPlan.value ? { tax_plan: 1 } : {}),
}))
const createBody: ComputedRef<IPaymentBody> = computed(() => {
  const goodsInBasket = goods.value.filter((i) => i.inBasket.value).map((i) => i.name)
  return {
    goods: goodsInBasket.filter((i) => ['Declaration', 'Cfr'].includes(i)),
    year_to_calc: Number(selectedYear.value),
    country: selectedCountry.value,
    price: total.value,
    tariff: tariff.value,
    services: services.value,
  }
})

const invoiceTotalPremiumTinkoff = computed(() => (props.invoice?.payments ? props.invoice?.payments.price : 0))

const totalText = computed(() => {
  if (surcharge.value > 0) {
    return 'Итого к доплате '
  } else if (hasBucket.value) {
    return 'Предоплата '
  } else {
    return 'Итого '
  }
})

function showDiscountRequest() {
  discountRequest.value.openModal()
}
function customerPayment(callbackConfig: { method: (link?: string) => Promise<void> }) {
  event('initiateCheckout')
  if (surcharge.value > 0) {
    surchargePayment(props.invoice.id)
      .then((res: { [key: string]: string }) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link)
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  } else {
    goodSelect(props.invoice.id, createBody.value)
      .then((res: Record<string, string>) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          // if (callbackConfig?.method) {
          callbackConfig?.method().then(() => {
            getInvoice(props.invoice.id)
            router.push({ name: 'invoice show' })
          })
          // }
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  }
}
function getPaymentLink(callbackConfig: Record<string, (link: string) => void>) {
  goodSelect(props.invoice.id, createBody.value).then((res: Record<string, string>) => {
    callbackConfig?.method?.(res?.link)
  })
}
function selectTariff(val: InvoiceTariffType) {
  tariff.value = val
}
function setupPrices() {
  goodsList.forEach((item: IGood) => {
    const prices: any = props.invoice?.all_price
    if (!!props.invoice?.all_price && Object.keys(props.invoice?.all_price).length === 0) {
      item.price.value = 0
      item.basePrice.value = 0
    }
    if (surcharge.value > 0) {
      item.price.value = surcharge.value
    } else {
      if (props.invoice?.all_price) {
        const price = prices[selectedYear.value][tariff.value][item.priceTag]
        const basePrice = prices[selectedYear.value][tariff.value][item.priceBaseTag]
        if (price && basePrice) {
          item.price.value = price
          item.basePrice.value = basePrice
        }
      }
      if (item.name === 'tax_plan') {
        item.price.value = 0
      }
    }
  })
}
function mergeYears() {
  if (props.invoice.all_price) {
    const yearsKeys = Object.keys(props.invoice?.all_price).map((year) => parseInt(year, 10))
    years.splice(0, years.length, ...yearsKeys)
    if (years.includes(props.invoice.year_to_calc)) {
      selectedYear.value = props.invoice.year_to_calc
    } else {
      selectedYear.value = years[years.length - 1]
    }
  } else {
    selectedYear.value = 2024
  }
}
function changeYear(val: number) {
  selectedYear.value = val
}
function changeCountry(val: string) {
  selectedCountry.value = val
}
function mergeTotal(val: number) {
  total.value = val
}

mergeYears()
setupPrices()
watch([tariff, selectedYear], setupPrices)
</script>

<template>
  <div class="container-list">
    <div v-if="surcharge > 0">
      <h1 class="mb-4">Требуется доплата</h1>
      <div class="mb-4">
        <p>Так как число операций выросло, то для продолжения оформления документов необходима доплата</p>
      </div>
    </div>
    <div v-else class="flex flex-wrap items-center justify-between mb-4">
      <div class="flex items-center">
        <h1 class="mr-3">Шаг 3. Выберите товары и узнайте их стоимость</h1>
        <ng-invoice-video
          ref="invoiceVideo"
          :show-button="true"
          :video-link="'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'"
        />
      </div>
      <div v-if="props.invoice.oto_timer">
        <o-t-o :ototimer="props.invoice.oto_timer" />
      </div>
    </div>
    <SplitWindow v-loading="loading">
      <template v-slot:left>
        <div v-if="surcharge === 0">
          <div class="min-h-[70px] px-4 bg-gray border--top flex items-center justify-between">
            <div class="flex items-center">
              <div>
                <img class="mr-4 dark-purple-icon" src="@/assets/shop_icon.svg" alt="shop_icon" />
              </div>
              <div>
                <h3>Товары</h3>
              </div>
            </div>
            <year-select
              @select-year="changeYear"
              @select-country="changeCountry"
              :is-admin="isAdmin"
              :years="Object.keys(props.invoice.all_price).map((i) => Number(i))"
              :year-to-calc="selectedYear"
            />
          </div>
          <div class="px-4 pt-4">
            <good-card v-for="good in goods" :key="good.name" :good="good" :year="selectedYear" />
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="sticky-basket">
          <div class="min-h-[70px] flex items-center p-4 bg-gray border--top justify-between">
            <div class="flex items-center">
              <img class="mr-4 dark-purple-icon" src="@/assets/basket_icon.svg" alt="basket_icon" />
              <h3>Стоимость услуг</h3>
            </div>
            <div>
              <el-tooltip v-if="!basketEmpty" content="Перейти на страницу оплаты">
                <el-button @click="customerPayment" :icon="CreditCard" type="success" :loading="loading">
                  ₽ {{ numberSeparator(total, ' ') }} {{ totalText }}
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <el-empty :image-size="44" v-if="basketEmpty" description="Не выбрано ни одного товара" />
          <div v-else class="p-4">
            <div v-if="surcharge === 0">
              <tariffs
                :tariff="props.invoice.tariff"
                :has-bucket="hasBucket"
                :personal-price="props.invoice.all_price[selectedYear].personal.personal || 0"
                @on-change-tariff="selectTariff"
                v-if="surcharge === 0"
              />
              <div class="el-divider-purple my-4" />
            </div>
            <Basket
              :goods="goods"
              :year="selectedYear"
              :broker-files="brokerFiles"
              :amount-operations="props.invoice.amount_operations.Declaration[selectedYear]"
              :add-selection="props.invoice.all_price[selectedYear][tariff].add_services"
              :personal-price="tariff === 'personal' ? props.invoice.all_price[selectedYear][tariff].personal || 0 : 0"
              :tariff="tariff"
              @invoice-total="mergeTotal"
            />
            <div class="flex mb-2" :class="tariff === 'minimal' ? 'justify-end' : 'justify-between'">
              <div v-if="surcharge === 0 && tariff !== 'minimal'">
                <promocode-form
                  :good-body="createBody"
                  :invoice-id="props.invoice.id"
                  :promo="props.invoice.promo?.code"
                  class="mb-2"
                />
                <el-button @click="showDiscountRequest" type="primary" :icon="Discount" link>хочу промокод</el-button>
              </div>
              <div class="flex justify-end">
                <h2 v-if="tinkoffPremiumInvoice">₽ {{ invoiceTotalPremiumTinkoff }}</h2>
                <h2 v-else>
                  <span>{{ totalText }}</span
                  >₽ {{ surcharge > 0 ? numberSeparator(surcharge, ' ') : numberSeparator(total, ' ') }}
                </h2>
              </div>
            </div>
            <finish-payment-dialog
              v-if="!isCustomer"
              :id="props.invoice.id"
              @send="customerPayment"
              @get-link="getPaymentLink"
              :tinkoff-link="tinkoffPremiumLink"
              :is-tinkoff-premium="tinkoffPremiumInvoice"
              :is-admin="isAdmin"
            />
          </div>
        </div>
      </template>
    </SplitWindow>
    <discount-request :id="props.invoice.id" ref="discountRequest" />
  </div>
</template>

<style scoped lang="sass"></style>
