import { defineStore } from 'pinia'
import { IBrokerAccount } from '@/entityes/invoice/cfr/cfr.type'
import { ref } from 'vue'

export const useCfrStore = defineStore('cfr store', () => {
  const brokerAccounts = ref<IBrokerAccount[] | undefined>(undefined)

  return {
    brokerAccounts,
  }
})
