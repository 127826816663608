import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import useWindowWidth from '@/use/useWindowWidth'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  color?: string
  mobile?: boolean
  width?: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionPopover',
  props: {
    color: {},
    mobile: { type: Boolean },
    width: {}
  },
  setup(__props: any) {

const { colors, isDark } = useColors()
const { screenLessValue } = useWindowWidth()


return (_ctx: any,_cache: any) => {
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: _unref(screenLessValue)(1200) ? 'top' : 'right',
    trigger: _unref(screenLessValue)(1200) ? 'click' : 'hover',
    width: _ctx.width || 300
  }, {
    reference: _withCtx(() => [
      _createElementVNode("span", null, [
        _createVNode(_component_el_icon, {
          color: _unref(isDark) ? _unref(colors).dark.ng_light_gray : _unref(colors).light.ng_deep_gray,
          size: "large",
          class: "cursor-point"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_QuestionFilled)
          ]),
          _: 1
        }, 8, ["color"])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["placement", "trigger", "width"]))
}
}

})