<script setup lang="ts">
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { Download, Loading, Files } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
interface IProps {
  noDelete?: boolean
  files: FileFromServer[]
  flex?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  noDelete: false,
})
interface IEmit {
  (e: 'delete', fileId: number): void
  (e: 'download', file: FileFromServer): void
}
const emit = defineEmits<IEmit>()
const { isDark } = useColors()
</script>

<template>
  <div :class="props.flex ? 'flex items-center' : ''">
    <div v-for="(file, index) in props.files" :key="'active_file_' + file.id">
      <div class="flex items-center justify-between">
        <div class="flex items-center mr-2">
          <el-icon size="18" class="mr-2"><Files /></el-icon>
          <span>{{ file.name }}</span>
        </div>
        <div class="flex items-center">
          <el-tooltip :effect="isDark ? 'dark' : 'light'" :content="`скачать ${file.name}`" placement="left">
            <el-icon
              size="18"
              class="mr-2 el-icon-loading font-weight--extra"
              v-if="file.isLoading"
              style="vertical-align: middle"
            >
              <Loading />
            </el-icon>
            <el-icon
              v-else
              @click="emit('download', file)"
              class="mr-2 cursor-point"
              size="18"
              style="vertical-align: middle"
            >
              <Download />
            </el-icon>
          </el-tooltip>
          <el-tooltip :effect="isDark ? 'dark' : 'light'" :content="`удалить ${file.name}`" placement="right">
            <img
              src="@/assets/delete_icon.svg"
              v-if="!noDelete"
              alt="delete_icon"
              @click="emit('delete', file.id)"
              width="18"
              class="cursor-point button--text-hover delete-icon"
            />
          </el-tooltip>
        </div>
      </div>
      <div
        v-if="props.files.length > 1 && index !== props.files.length - 1 && !props.flex"
        class="el-divider-purple my-2"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
