<script setup lang="ts">
import { FileFromServer } from '@/entityes/invoice/file/file.type'

interface IProps {
  files: FileFromServer[]
}
const props = defineProps<IProps>()
</script>

<template>
  <div>
    <div v-for="file in props.files" :key="'file_to_delete_' + file.id">
      <div class="flex mb-4">
        <i class="el-icon-close font-color--red font-weight--extra"></i>
        <span class="text-strike opacity">{{ file.name }}</span>
      </div>
      <div class="mb-4">
        <div class="el-divider-purple" />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
