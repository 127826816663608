import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex items-center" }

import { useRouter } from 'vue-router'
import usePolitic from '@/use/usePolitic'
import { StarFilled, Discount, Histogram, UserFilled, Memo, Cherry } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NgAdminMenu',
  setup(__props) {

const router = useRouter()
const { isAdmin } = usePolitic()

const settingsMenuDescriptor = [
  {
    title: 'Промокоды',
    icon: Discount,
    clickAction: () => {
      router.push({
        name: 'promo index',
      })
    },
  },
  // {
  //   title: 'Брокеры',
  //   icon: StarFilled,
  //   clickAction: () => {
  //     router.push({
  //       name: 'brokers index',
  //     })
  //   },
  // },
  {
    title: 'Оксм',
    icon: Memo,
    clickAction: () => {
      router.push({
        name: 'oksm index',
      })
    },
  },
  // {
  //   title: 'Профили',
  //   icon: UserFilled,
  //   clickAction: () => {
  //     router.push({
  //       name: 'profiles',
  //     })
  //   },
  // },
  {
    title: 'Нал план',
    icon: Histogram,
    clickAction: () => {
      router.push({
        name: 'tax plan index',
      })
    },
  },
]

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_unref(isAdmin))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(settingsMenuDescriptor, (item) => {
          return _createElementVNode("div", {
            key: item.title,
            onClick: item.clickAction,
            class: "clickable font-weight--semi cursor-point"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_icon, {
                size: "small",
                class: "mr-2"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                ]),
                _: 2
              }, 1024),
              _createTextVNode(" " + _toDisplayString(item.title), 1)
            ])
          ], 8, _hoisted_2)
        }), 64))
      ]))
    : _createCommentVNode("", true)
}
}

})