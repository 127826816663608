import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex mb-4" }
const _hoisted_2 = { class: "text-strike opacity" }

import { FileFromServer } from '@/entityes/invoice/file/file.type'

interface IProps {
  files: FileFromServer[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FileFromServerDeleted',
  props: {
    files: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (file) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'file_to_delete_' + file.id
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "el-icon-close font-color--red font-weight--extra" }, null, -1)),
          _createElementVNode("span", _hoisted_2, _toDisplayString(file.name), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-4" }, [
          _createElementVNode("div", { class: "el-divider-purple" })
        ], -1))
      ]))
    }), 128))
  ]))
}
}

})