import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center mr-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "el-divider-purple my-2"
}

import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { Download, Loading, Files } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
interface IProps {
  noDelete?: boolean
  files: FileFromServer[]
  flex?: boolean
}
interface IEmit {
  (e: 'delete', fileId: number): void
  (e: 'download', file: FileFromServer): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FileFromServerItem',
  props: {
    noDelete: { type: Boolean, default: false },
    files: {},
    flex: { type: Boolean }
  },
  emits: ["delete", "download"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(props.flex ? 'flex items-center' : '')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (file, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'active_file_' + file.id
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_icon, {
              size: "18",
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Files))
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(file.name), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_tooltip, {
              effect: _unref(isDark) ? 'dark' : 'light',
              content: `скачать ${file.name}`,
              placement: "left"
            }, {
              default: _withCtx(() => [
                (file.isLoading)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      size: "18",
                      class: "mr-2 el-icon-loading font-weight--extra",
                      style: {"vertical-align":"middle"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Loading))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_el_icon, {
                      key: 1,
                      onClick: ($event: any) => (emit('download', file)),
                      class: "mr-2 cursor-point",
                      size: "18",
                      style: {"vertical-align":"middle"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Download))
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
              ]),
              _: 2
            }, 1032, ["effect", "content"]),
            _createVNode(_component_el_tooltip, {
              effect: _unref(isDark) ? 'dark' : 'light',
              content: `удалить ${file.name}`,
              placement: "right"
            }, {
              default: _withCtx(() => [
                (!_ctx.noDelete)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _imports_0,
                      alt: "delete_icon",
                      onClick: ($event: any) => (emit('delete', file.id)),
                      width: "18",
                      class: "cursor-point button--text-hover delete-icon"
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["effect", "content"])
          ])
        ]),
        (props.files.length > 1 && index !== props.files.length - 1 && !props.flex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 2))
}
}

})