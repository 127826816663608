import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col justify-end p-4 h-[76%]"
}
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between items-center p-4 mb-4 step-box bg-green"
}
const _hoisted_8 = { class: "flex justify-end" }
const _hoisted_9 = {
  key: 0,
  class: "p-4"
}

import useGoodsDescription from '@/describers/GoodsDescriber'
import { computed, ref, defineAsyncComponent, watch, reactive } from 'vue'
import useGtagManager from '@/use/useGtagManager'
import usePolitic from '@/use/usePolitic'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
// import { GoodsPreviewMsg } from '@/05-Features/ResultMsg'
import ResultGoodCard from '@/07-Shared/ui/ResultGoodCard.vue'
import DeclarationSummary from '@/05-Features/DeclarationSummary/ui/DeclarationSummary.vue'
import DeclarationCorrectionNumberInput from '@/05-Features/DeclarationVersionSet/ui/DeclarationCorrectionNumberInput.vue'
import { useRouter } from 'vue-router'
import GoodsResultFiles from '@/05-Features/ResultFiles/ui/GoodsResultFiles.vue'
import CfrOnlineResults from '@/05-Features/CfrOnlineResults/ui/CfrOnlineResults.vue'
import { useCfr } from '@/04-Widgets/ResultStep/api/useCfr.request'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import img from '@/07-Shared/ui/ImgDescriber'
import GuruLogo from '@/assets/guru_logo.svg'
import fileLoader from '@/06-Entities/File/model/FileLoader'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import { FileItem } from '@/06-Entities/File'
import { ILoaderDescriber, PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodsPreview',
  props: {
    id: {}
  },
  setup(__props: any) {

const SidebarLoader = defineAsyncComponent(() => import('@/05-Features/SidebarLoader/ui/SidebarLoader.vue'))
const props = __props
const invoiceStore = useInvoiceStore()
const { getInvoiceList, getInvoice } = useInvoiceRepository()
const { goodsList } = useGoodsDescription()
const { event } = useGtagManager()
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const declarationStore = useDeclarationStore()
const { getCfr } = useCfr()
const { getDeclaration, loadResultFilesForDeclaration } = useDeclarationRepository()
const goods = computed(() => invoiceStore?.invoice?.goods)
const renderGoods = computed(() => {
  if (goods.value) {
    return goodsList.filter((p) => Object.keys(goods?.value || {}).includes(p.name))
  }
})
const actions = computed(() => invoiceStore.invoice?.status.actions_for_goods)
const additional = computed(() => invoiceStore.invoice?.additional)
const declarationId = computed(() => {
  if (goods.value) {
    return goods.value?.Declaration
  }
})
const invoiceVideo = ref<typeof NgInvoiceVideo>()
const hasOnlineCfr = ref(false)
const showSidebar = ref(false)
const declarationLoader = {
  title: 'для декларации',
  text: 'Нажмите или перетащите файлы для загрузки итоговых файлов для декларации',
  serverTag: 'declarations',
  name: 'Declaration',
  fileFormat: /.*\.(zip|xlsx|dc2|dc1|xml|pdf)$/,
  img: img.csv,
  logo: GuruLogo,
}
const cfrLoader = {
  title: 'для ОДС',
  text: 'Нажмите или перетащите файлы для загрузки итоговых файлов для ОДС',
  serverTag: 'cfrs',
  name: 'Cfr',
  fileFormat: /.*\.(zip)$/,
  img: img.csv,
  logo: GuruLogo,
}
const currentLoader = ref<ILoaderDescriber>(declarationLoader)
const loader = ref<any>(fileLoader(currentLoader.value))

function toggleResultFiles(val: { goodName: string; show: boolean }) {
  const good = goodsList.find((good) => good.name === val.goodName)
  if (good && good.showResultFiles) {
    good.showResultFiles.value = val.show
  }
}
function openLoader(goodName: string) {
  if (goodName === 'Declaration') currentLoader.value = declarationLoader
  if (goodName === 'Cfr') currentLoader.value = cfrLoader
  showSidebar.value = true
}
function sendFiles(goodName: string) {
  const tag = goodName === 'Declaration' ? 'declarations' : 'cfrs'
  const body = {
    [tag]: {
      ...loader.value.localFiles,
    },
  } as PreProcessingFiles
  loadResultFilesForDeclaration(props.id, body)
    .then(() => {
      ElNotification({
        title: 'Файлы декларации',
        message: 'успешно обновлены',
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `при загрузке файлов ${err}`,
        type: 'error',
      })
    })
}
if (isAdmin?.value) {
  event('internal_traffic')
}
// TODO this is a very strange decision becouse asking for a list of orders to check the video display is very strange
getInvoiceList().then((res) => {
  if (res?.invoices?.length === 1 && !isAdmin.value && !isPartner.value) {
    invoiceVideo.value?.openVideoModal()
  }
})
const { initAsync } = useAsyncInvoiceReload()
const loading = ref(false)
function init() {
  loading.value = true
  getInvoice(props.id)
    .then((res) => {
      return initAsync(res)
    })
    .then((res) => {
      if (res.goods?.Declaration) {
        getDeclaration(res.goods.Declaration)
      }
      if (res.goods?.Cfr) {
        getCfr(res.goods.Cfr)
      }
      loading.value = false
    })
}
init()
watch(currentLoader, (currentLoader) => {
  loader.value = fileLoader(currentLoader)
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "mb-4 mr-4" }, "Итоговые документы", -1)),
      _createVNode(NgInvoiceVideo, {
        ref_key: "invoiceVideo",
        ref: invoiceVideo,
        "show-button": true,
        "video-link": 'https://www.youtube.com/embed/iR-A0IPepn4?si=UOfTDNqyxCjKZQux'
      }, null, 512)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderGoods.value, (good) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "step-box no-select mb-4",
        key: good.name
      }, [
        _createVNode(ResultGoodCard, {
          "good-name": good.name,
          onShowFiles: toggleResultFiles
        }, _createSlots({
          left: _withCtx(() => [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(good.title) + " " + _toDisplayString(_unref(invoiceStore).invoice?.year_to_calc), 1),
            (good.name === 'Declaration' && _unref(declarationStore).declaration)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (!!_unref(invoiceStore).invoice)
                    ? (_openBlock(), _createBlock(DeclarationSummary, {
                        key: 0,
                        actions: actions.value
                      }, null, 8, ["actions"]))
                    : _createCommentVNode("", true),
                  (_unref(declarationStore).declaration)
                    ? (_openBlock(), _createBlock(DeclarationCorrectionNumberInput, {
                        key: 1,
                        declaration: _unref(declarationStore).declaration
                      }, null, 8, ["declaration"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (good.name === 'Cfr')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (goods.value && actions.value?.['Cfr']?.includes('Need_file_for_last_year'))
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        class: "mb-4",
                        size: "small",
                        type: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'file_in_invoice' })))
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Загрузить ")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          right: _withCtx(() => [
            (_unref(isAdmin) && good.showResultFiles?.value === false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (loader.value.localFiles.files.length > 0 && loader.value.config.name === good.name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_el_scrollbar, {
                          class: "pr-8",
                          "max-height": 100,
                          always: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(loader.value.localFiles.file_names, (name) => {
                              return (_openBlock(), _createBlock(_unref(FileItem), {
                                name: name,
                                key: name,
                                onClose: loader.value.onRemoveLocalFile
                              }, null, 8, ["name", "onClose"]))
                            }), 128))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_button, {
                          onClick: ($event: any) => (sendFiles(good.name)),
                          size: "small",
                          type: "info"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("Отправить файлы")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    (goods.value && good.name === 'Declaration')
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          icon: _unref(UploadFilled),
                          round: "",
                          plain: "",
                          onClick: ($event: any) => (openLoader(good.name)),
                          class: "max-w-[400px]"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Заменить итоговые файлы ")
                          ])),
                          _: 2
                        }, 1032, ["icon", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_unref(SidebarLoader), {
                    "loader-describer": loader.value,
                    "show-sidebar": showSidebar.value,
                    "onUpdate:showSidebar": _cache[1] || (_cache[1] = ($event: any) => ((showSidebar).value = $event)),
                    onReadedFile: loader.value.onUpdateFilesEvent,
                    onDeleteLocalFile: loader.value.onRemoveLocalFile,
                    onDeleteServerFile: loader.value.onAddDeleteFile,
                    onUndoDeleteFile: loader.value.undoDeleteFiles
                  }, null, 8, ["loader-describer", "show-sidebar", "onReadedFile", "onDeleteLocalFile", "onDeleteServerFile", "onUndoDeleteFile"])
                ]))
              : _createCommentVNode("", true),
            (good.showResultFiles?.value && goods.value?.[good.name as GoodsTypeName])
              ? (_openBlock(), _createBlock(GoodsResultFiles, {
                  key: 1,
                  "good-name": good.name,
                  "good-id": goods.value[good.name as GoodsTypeName]
                }, null, 8, ["good-name", "good-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (good.name === 'Cfr')
            ? {
                name: "bottom",
                fn: _withCtx(() => [
                  (good.showResultFiles?.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (goods.value?.['Cfr'])
                          ? (_openBlock(), _createBlock(CfrOnlineResults, {
                              key: 0,
                              "good-id": goods.value['Cfr']
                            }, null, 8, ["good-id"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["good-name"])
      ])), [
        [_directive_loading, loading.value]
      ])
    }), 128))
  ]))
}
}

})