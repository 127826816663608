import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "mb-12"
}
const _hoisted_7 = {
  key: 2,
  class: "flex items-center mb-12"
}
const _hoisted_8 = { key: 3 }
const _hoisted_9 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex items-center justify-between mb-4" }

import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { DragAndDrop, FileItem, FileFromServerItem, FileFromServerDeleted } from '@/06-Entities/File'
import { computed, defineAsyncComponent } from 'vue'
import { FileLoaderType } from '@/06-Entities/File'
import useWindowWidth from '@/use/useWindowWidth'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { CircleCloseFilled } from '@element-plus/icons-vue'
interface IProps {
  loaderDescriber: FileLoaderType
  showDeleteButton?: boolean
  showInstruction?: boolean
  showServerFiles?: boolean
}
interface IEmits {
  (e: 'readedFile', value: PreProcessingFiles): void
  (e: 'deleteLocalFile', index: number): void
  (e: 'deleteServerFile', value: number): void
  (e: 'undoDeleteFile'): void
  (e: 'saveFiles'): void
  (e: 'downloadServerFile', value: FileFromServer): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarLoader',
  props: /*@__PURE__*/_mergeModels({
    loaderDescriber: {},
    showDeleteButton: { type: Boolean, default: false },
    showInstruction: { type: Boolean, default: true },
    showServerFiles: { type: Boolean, default: false }
  }, {
    "showSidebar": { type: Boolean, ...{ required: true, default: false } },
    "showSidebarModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["readedFile", "deleteLocalFile", "deleteServerFile", "undoDeleteFile", "saveFiles", "downloadServerFile"], ["update:showSidebar"]),
  setup(__props: any, { emit: __emit }) {

const IbDownloadFileInstruction = defineAsyncComponent(
  () => import('@/04-Widgets/LoadFileStep/ui/IbDownloadFileInstruction.vue')
)

const showSidebar = _useModel<boolean>(__props, 'showSidebar')
const { screenLessValue } = useWindowWidth()
const props = __props
const isExanteLoaderDescriber = computed<boolean>(
  () => props.loaderDescriber.config.serverTag === 'exante_virtual_files'
)
const emit = __emit

function dndReadedFiles(value: PreProcessingFiles) {
  emit('readedFile', value)
}
function fileItemDeleteLocalLoadedItem(index: number) {
  emit('deleteLocalFile', index)
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: showSidebar.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showSidebar).value = $event)),
    direction: _unref(screenLessValue)(993) ? 'ttb' : 'rtl',
    size: _unref(screenLessValue)(993) ? '80%' : '30%',
    "destroy-on-close": ""
  }, {
    header: _withCtx(({ titleId }) => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: props.loaderDescriber.config.logo,
            alt: "plus_icon",
            class: "logo-img mr-2"
          }, null, 8, _hoisted_2),
          (props.loaderDescriber.config.serverTag === 'exante_virtual_files')
            ? (_openBlock(), _createElementBlock("h3", {
                key: 0,
                id: titleId
              }, " Инструкция для клиентов Exante ", 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("h3", {
                key: 1,
                id: titleId
              }, "Загрузить " + _toDisplayString(props.loaderDescriber.config.title), 9, _hoisted_4))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(props.loaderDescriber.config.description), 1)
      ]),
      (isExanteLoaderDescriber.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[5] || (_cache[5] = [
            _createElementVNode("iframe", {
              src: "https://www.youtube.com/embed/S2wMoKIKWfk?si=lwHLZJVjyba8nNBC",
              title: "YouTube video player",
              width: "100%",
              height: "300px",
              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
              loading: "lazy"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_unref(DragAndDrop), {
              text: props.loaderDescriber.config.text,
              "file-format": props.loaderDescriber.config.fileFormat,
              onReadedFiles: dndReadedFiles
            }, null, 8, ["text", "file-format"]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.loaderDescriber.localFiles.file_names, (item, index) => {
                return (_openBlock(), _createBlock(_unref(FileItem), {
                  name: item,
                  class: "w-full",
                  key: 'local_files_' + item,
                  onClose: 
            () => {
              fileItemDeleteLocalLoadedItem(index)
            }
          
                }, null, 8, ["name", "onClose"]))
              }), 128))
            ])
          ])),
      (props.loaderDescriber.deleteFiles.length > 0 || props.loaderDescriber.localFiles.file_names.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = 
          () => {
            showSidebar.value = false
            emit('saveFiles')
          }
        ),
              class: "button-main w-full",
              type: "primary"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Сохранить ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (props.showServerFiles)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (props.loaderDescriber.getFilesFromServer.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "mb-4" }, "Загруженные файлы", -1)),
                  _createVNode(_unref(FileFromServerItem), {
                    files: props.loaderDescriber.getFilesFromServer,
                    "no-delete": !props.showDeleteButton,
                    onDelete: _cache[1] || (_cache[1] = (index) => emit('deleteServerFile', index)),
                    onDownload: _cache[2] || (_cache[2] = (file) => emit('downloadServerFile', file))
                  }, null, 8, ["files", "no-delete"])
                ]))
              : _createCommentVNode("", true),
            (props.loaderDescriber.deleteFiles.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Файлы к удалению", -1)),
                    _createVNode(_component_el_button, {
                      icon: _unref(CircleCloseFilled),
                      type: "danger",
                      size: "small",
                      link: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('undoDeleteFile')))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Отменить")
                      ])),
                      _: 1
                    }, 8, ["icon"])
                  ]),
                  (props.loaderDescriber.deleteFiles.length > 0)
                    ? (_openBlock(), _createBlock(_unref(FileFromServerDeleted), {
                        key: 0,
                        files: props.loaderDescriber.getFileToDelete
                      }, null, 8, ["files"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (props.showInstruction)
        ? (_openBlock(), _createBlock(_unref(IbDownloadFileInstruction), {
            key: 4,
            broker: props.loaderDescriber?.config?.serverTag
          }, null, 8, ["broker"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "direction", "size"]))
}
}

})