<script setup lang="ts">
import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { DragAndDrop, FileItem, FileFromServerItem, FileFromServerDeleted } from '@/06-Entities/File'
import { computed, defineAsyncComponent } from 'vue'
import { FileLoaderType } from '@/06-Entities/File'
import useWindowWidth from '@/use/useWindowWidth'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { CircleCloseFilled } from '@element-plus/icons-vue'
const IbDownloadFileInstruction = defineAsyncComponent(
  () => import('@/04-Widgets/LoadFileStep/ui/IbDownloadFileInstruction.vue')
)

interface IProps {
  loaderDescriber: FileLoaderType
  showDeleteButton?: boolean
  showInstruction?: boolean
  showServerFiles?: boolean
}
const showSidebar = defineModel<boolean>('showSidebar', { required: true, default: false })
const { screenLessValue } = useWindowWidth()
const props = withDefaults(defineProps<IProps>(), {
  showDeleteButton: false,
  showInstruction: true,
  showServerFiles: false,
})
const isExanteLoaderDescriber = computed<boolean>(
  () => props.loaderDescriber.config.serverTag === 'exante_virtual_files'
)
interface IEmits {
  (e: 'readedFile', value: PreProcessingFiles): void
  (e: 'deleteLocalFile', index: number): void
  (e: 'deleteServerFile', value: number): void
  (e: 'undoDeleteFile'): void
  (e: 'saveFiles'): void
  (e: 'downloadServerFile', value: FileFromServer): void
}

const emit = defineEmits<IEmits>()

function dndReadedFiles(value: PreProcessingFiles) {
  emit('readedFile', value)
}
function fileItemDeleteLocalLoadedItem(index: number) {
  emit('deleteLocalFile', index)
}
</script>

<template>
  <el-drawer
    v-model="showSidebar"
    :direction="screenLessValue(993) ? 'ttb' : 'rtl'"
    :size="screenLessValue(993) ? '80%' : '30%'"
    destroy-on-close
  >
    <template #header="{ titleId }">
      <div>
        <div class="flex items-center justify-center">
          <img :src="props.loaderDescriber.config.logo" alt="plus_icon" class="logo-img mr-2" />
          <h3 :id="titleId" v-if="props.loaderDescriber.config.serverTag === 'exante_virtual_files'">
            Инструкция для клиентов Exante
          </h3>
          <h3 :id="titleId" v-else>Загрузить {{ props.loaderDescriber.config.title }}</h3>
        </div>
      </div>
    </template>
    <div>
      <p>{{ props.loaderDescriber.config.description }}</p>
    </div>
    <div v-if="isExanteLoaderDescriber">
      <iframe
        src="https://www.youtube.com/embed/S2wMoKIKWfk?si=lwHLZJVjyba8nNBC"
        title="YouTube video player"
        width="100%"
        height="300px"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      >
      </iframe>
    </div>
    <div class="mb-12" v-else>
      <DragAndDrop
        :text="props.loaderDescriber.config.text"
        :file-format="props.loaderDescriber.config.fileFormat"
        @readed-files="dndReadedFiles"
      />
      <div>
        <FileItem
          v-for="(item, index) in props.loaderDescriber.localFiles.file_names"
          :name="item"
          class="w-full"
          :key="'local_files_' + item"
          @close="
            () => {
              fileItemDeleteLocalLoadedItem(index)
            }
          "
        />
      </div>
    </div>
    <div
      class="flex items-center mb-12"
      v-if="props.loaderDescriber.deleteFiles.length > 0 || props.loaderDescriber.localFiles.file_names.length > 0"
    >
      <el-button
        @click="
          () => {
            showSidebar = false
            emit('saveFiles')
          }
        "
        class="button-main w-full"
        type="primary"
      >
        Сохранить
      </el-button>
    </div>
    <div v-if="props.showServerFiles">
      <div v-if="props.loaderDescriber.getFilesFromServer.length > 0" class="mb-8">
        <h3 class="mb-4">Загруженные файлы</h3>
        <FileFromServerItem
          :files="props.loaderDescriber.getFilesFromServer"
          :no-delete="!props.showDeleteButton"
          @delete="(index) => emit('deleteServerFile', index)"
          @download="(file) => emit('downloadServerFile', file)"
        />
      </div>
      <div v-if="props.loaderDescriber.deleteFiles.length > 0">
        <div class="flex items-center justify-between mb-4">
          <h3>Файлы к удалению</h3>
          <el-button :icon="CircleCloseFilled" type="danger" size="small" link @click="emit('undoDeleteFile')"
            >Отменить</el-button
          >
        </div>
        <FileFromServerDeleted
          v-if="props.loaderDescriber.deleteFiles.length > 0"
          :files="props.loaderDescriber.getFileToDelete"
        />
      </div>
    </div>
    <ib-download-file-instruction v-if="props.showInstruction" :broker="props.loaderDescriber?.config?.serverTag" />
  </el-drawer>
</template>

<style lang="sass" scoped></style>
