import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AddCfrAccount from '@/04-Widgets/CfrWidget/ui/AddCfrAccount.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CfrPage',
  setup(__props) {

const invoiceStore = useInvoiceStore()

return (_ctx: any,_cache: any) => {
  return (_unref(invoiceStore).invoice)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(AddCfrAccount, {
          good_id: _unref(invoiceStore).invoice.goods.Cfr
        }, null, 8, ["good_id"])
      ]))
    : _createCommentVNode("", true)
}
}

})