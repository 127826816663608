<script setup lang="ts">
import { IAddService } from '@/entityes/invoice/invoice.type'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import { computed, ref } from 'vue'
import { SuccessFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import useFileDownload from '@/use/useFileDownload'
import { transformFileFromServer } from '@/06-Entities/File'
import useTypeWriter from '@/plugins/useTypeWriter'

interface IProps {
  invoiceId: string | number
  services: IAddService[] | undefined
}
const props = defineProps<IProps>()
const { incomesDeductionsDescribers: describer } = AddIncomesRefundsDescriber()
const { colors, isDark } = useColors()
const { downloadServiceFile } = useFileDownload()
const activeNames = ref('')
const { renderString } = useTypeWriter('загрузка...')
const renderServices = computed(() => {
  return props.services?.filter(
    (service) =>
      !['guru_file', 'declaration_pdf', 'cfr_pdf', 'tax_plan', 'personal_manager'].includes(service.service_type)
  )
})
function downLoadFile(file: FileFromServer) {
  file.isLoading = true
  const params = {
    invoiceId: props.invoiceId,
    serviceId: props.services?.find((service) => service.service_type == file.service_type)?.id,
    file,
  }
  downloadServiceFile(params).finally(() => (file.isLoading = false))
}
</script>

<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item v-for="service in renderServices" :name="service.service_type">
        <template #title>
          <div class="flex">
            <p class="mx-4">{{ describer.find((i) => i.tag === service.service_type)?.name }}</p>
            <div v-if="service.files.length > 0" class="flex items-center">
              <el-icon class="mr-1" :color="isDark ? colors.dark.ng_green : colors.light.ng_green">
                <SuccessFilled />
              </el-icon>
              <p class="dark:text-dark-green text-light-green">Файлы загружены</p>
            </div>
            <div class="flex items-center" v-else>
              <el-icon class="mr-1" :color="isDark ? colors.dark.ng_red : colors.light.ng_error">
                <SuccessFilled />
              </el-icon>
              <p class="dark:text-dark-red text-light-red">Файлы не загружены</p>
            </div>
          </div>
        </template>
        <div class="flex items-center flex-wrap">
          <div v-for="file in service.files.map((i) => transformFileFromServer(i))">
            <el-tooltip :effect="isDark ? 'light' : 'dark'" :content="`скачать ${file.serverName}`" placement="bottom">
              <el-tag
                effect="light"
                class="cursor-point min-h-[30px] w-[260px] card-hover m-2"
                size="small"
                type="primary"
                @click="downLoadFile(file)"
              >
                <div v-if="file.isLoading" class="flex items-center">
                  <el-icon class="el-icon-loading font-weight--extra mr-2" style="vertical-align: middle">
                    <Loading />
                  </el-icon>
                  <p>{{ renderString }}</p>
                </div>
                <div v-else>
                  <p>{{ file.name }}</p>
                </div>
              </el-tag>
            </el-tooltip>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style scoped lang="sass"></style>
