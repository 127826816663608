import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-2 my-2 font-weight--semi" }
const _hoisted_2 = { class: "flex items-center" }

import useTextFilters from '@/plugins/TextFilters'
import { Document } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  name: string
}
interface IEmits {
  (e: 'close'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FileItem',
  props: {
    name: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { subStringFileName } = useTextFilters()
const { colors, isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tooltip, {
      effect: "dark",
      content: _ctx.name,
      disabled: _ctx.name.length > 20,
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tag, {
          class: "flex justify-between",
          size: "large",
          type: "primary",
          closable: "",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_icon, {
                class: "mr-2",
                size: "18",
                color: _unref(isDark) ? _unref(colors).dark.ng_purple : _unref(colors).light.ng_purple
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Document))
                ]),
                _: 1
              }, 8, ["color"]),
              _createElementVNode("p", null, _toDisplayString(_unref(subStringFileName)(props.name, 40)), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["content", "disabled"])
  ]))
}
}

})